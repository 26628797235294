import { getNext, getPrevious } from '@/lib/Array';
import { TIME_UNIT_MINUTES } from '@/lib/Constants';
import {
    CalculateRateTaskRate,
    ChangeTaskDuration,
    ChangeTaskQuantity,
    IsDelay,
    IsEosTask,
    IsFloatDelay,
    IsMoveBlocking,
    IsRateTask,
    IsRelatedToTask,
    MoveTaskBy,
    MoveTaskTo,
    PullTaskBackwardTo,
    PullTaskForwardTo,
    PushTaskBackwardAtLeastTo,
    PushTaskBackwardTo,
    PushTaskForwardTo,
    RoundTaskQuantity,
    TaskIntersectsTimespan,
    TaskStartsDuring
} from '@/lib/services/Task';
import {
    AddTaskBeforeOrAfterCommand,
    CycleType,
    DelayTypes,
    CreateAdHocTaskCommand,
    DeleteAdHocTaskCommand,
    DeleteTaskCommand,
    PlannedEquipmentViewModel,
    SetTaskEquipmentCommand,
    SplitAdhocTaskCommand,
    SplitTaskCommand,
    TaskClasses,
    TaskCommandResponse,
    TaskTypeViewModel,
    UpdateAdHocTaskCommand,
    UpdateTaskTimesCommand
} from '@/models/api';
import ClientTaskModel from '@/models/client/client-task';
import WeekTask from '@/models/client/week-task';
import Timespan from '@/models/client/timespan';
import { NoGoType } from '@/models/client/types/no-go-type';
import dayjs, { Dayjs } from 'dayjs';
import { v4 } from 'uuid';
import NoGoZoneFinder from '@/lib/services/NoGoZoneFinder';
import { cloneDeep } from 'lodash';
import { TimeBlock, TimePeriod } from '@/models/client/time-block';
import { IsFloatDelayType } from '@/lib/services/TaskType';
import { extractBlastPacketDisplayInformation } from '@/lib/stores/Transforms';
import { ClientRowBlastPacketTargetDisplayInformation } from '@/lib/stores/Production/ShiftWindowActualsStore';
import { AddTaskAtTimePointCommand } from '@/models/client/Commands/add-task-at-time-point-command';
import Locations from '@/lib/data/Locations';

export interface ClientOnlyTaskCommand {
    type: string;
}

export interface ClientOnlyRemoveTaskCommand extends ClientOnlyTaskCommand {
    taskId: string;
}

export function DoNothing(inputTasks: ClientTaskModel[], _command: any): ClientTaskModel[] {
    return inputTasks;
}

export function NoOpApiCall(_command: any) : Promise<TaskCommandResponse> {
    const response: TaskCommandResponse = {
        _type: 'TaskCommandResponse',
        planningUpdateTag: null,
        updatedLocation: null
    };

    return new Promise<TaskCommandResponse>((resolve,reject)=>resolve(response));
}

export function ResizeTask<T extends TimeBlock>(inputTasks: T[], command: { taskId: string; durationInMinutes: number })
{
    const taskBeingResized = getTask(inputTasks, command.taskId);

    const impactingTasks = getImpactingTasks(inputTasks, taskBeingResized);
    const taskBeingResizedIndex = getTaskIndex(impactingTasks, taskBeingResized.id);
    const originalTask = cloneDeep(taskBeingResized);

    // TODO: Removed split removal for now, because I thought this was handled elsewhere?
    /*
    * if (isMiddleOfSplit(impactingTasks, taskBeingResizedIndex) && taskBeingResized.isManualSplit === false) {
        taskBeingResized.isManualSplit = true;
        impactingTasks[taskBeingResizedIndex - 1].isAutoSplit = false;
        impactingTasks[taskBeingResizedIndex + 1].isAutoSplit = false;
    }
    * */

    ChangeTaskDuration(taskBeingResized, command.durationInMinutes);

    let tempOriginal = originalTask;
    let tempUpdated = taskBeingResized;
    for (let i = taskBeingResizedIndex + 1; i < impactingTasks.length; i++) {
        const task = impactingTasks[i];

        const newOriginalTask = cloneDeep(task);
        applyInfluenceOnSubsequentTask(tempOriginal, tempUpdated, task);

        tempOriginal = newOriginalTask;
        tempUpdated = task;
    }

    return inputTasks;
}

export function RemoveTaskWithNoSideEffects(inputTasks: ClientTaskModel[], command: ClientOnlyRemoveTaskCommand) {
    const taskBeingDeletedIndex = getTaskIndex(inputTasks, command.taskId);

    inputTasks.splice(taskBeingDeletedIndex, 1);
}

export function IsolateFragmentIfRequired(inputTasks: ClientTaskModel[], taskId: string): string[] {
    const taskIndex = getTaskIndex(inputTasks, taskId);
    let transferOwnershipOffset = 0;
    let changeTargetTaskCorrelationId = false;

    let isAutosplit = false;

    const tasksToRemove: string[] = [];

    if(isMiddleOfAutoSplit(inputTasks, taskIndex))
        throw new Error("Cannot isolate a fragment that is the split part of an autosplit.");

    if(isLastOfAutoSplit(inputTasks, taskIndex)){
        changeTargetTaskCorrelationId = true;
        tasksToRemove.push(inputTasks[taskIndex - 1].id);
        inputTasks[taskIndex].isAutoSplit = false;
        isAutosplit = true;
    }

    if(isFirstOfAutoSplit(inputTasks, taskIndex)){
        // offset to start transferring ownership from the autosplit, as transferOwnership won't transfer if you're the
        // very first task of an autosplit sequence, which we might well be
        transferOwnershipOffset = 1;
        isAutosplit = true;
    }

    // we're not interested if it's not an autosplit
    if(!isAutosplit)
        return [];

    const extraTasksToRemove = transferOwnershipOfFollowingAutosplits(inputTasks, taskIndex+transferOwnershipOffset, inputTasks[taskIndex]?.correlationId ?? null, true);

    if(changeTargetTaskCorrelationId)
        inputTasks[taskIndex].correlationId = v4();

    return [... tasksToRemove, ... extraTasksToRemove];
}

export function SeparateSplitAtGivenFragmentIfRequired(inputTasks: ClientTaskModel[], taskId: string, separateBeforeFragment: boolean): string[] {
    let taskIndex = getTaskIndex(inputTasks, taskId);

    const tasksToRemove: string[] = [];

    if(separateBeforeFragment) {
        if(isLastOfAutoSplit(inputTasks, taskIndex)){
            inputTasks[taskIndex].isAutoSplit = false;
            tasksToRemove.push(inputTasks[taskIndex - 1].id);
        }
    } else {
        if(isMiddleOfAutoSplit(inputTasks, taskIndex))
            tasksToRemove.push(inputTasks[taskIndex].id);

        taskIndex++;
    }

    const extraTasksToRemove = transferOwnershipOfFollowingAutosplits(inputTasks, taskIndex, inputTasks[taskIndex]?.correlationId ?? null, true);

    return [... tasksToRemove, ... extraTasksToRemove];
}

export function ReplacePlannedCycleIdForFollowingTasksIfRequired(inputTasks: ClientTaskModel[], taskId: string, replaceBeforeTargetTask: boolean): { taskId: string, changedCycleId: string }[] {
    const tasksWithReplacedCycles = [] as { taskId: string, changedCycleId: string}[];

    let taskIndex = getTaskIndex(inputTasks, taskId);
    const targetTask = inputTasks[taskIndex];

    const originalPlannedCycleId = targetTask.plannedCycleId;

    if(replaceBeforeTargetTask){
        // We're replacing beforehand, but there aren't any members of our cycle prior to this, so don't bother
        if(taskIndex === 0 || inputTasks[taskIndex-1].plannedCycleId !== originalPlannedCycleId)
            return [];
    } else {
        // We're replacing afterwards, but there aren't any members of our cycle after this, so don't bother
        if(taskIndex >= inputTasks.length - 1 || inputTasks[taskIndex+1].plannedCycleId !== originalPlannedCycleId)
            return [];

        taskIndex++;
    }

    const replacementPlannedCycleId = v4();

    for(let i = taskIndex; i < inputTasks.length; i++) {
        const task = inputTasks[i];

        if(task.plannedCycleId !== originalPlannedCycleId)
            break;

        task.plannedCycleId = replacementPlannedCycleId;
        tasksWithReplacedCycles.push({taskId: task.id, changedCycleId: replacementPlannedCycleId});
    }

    return tasksWithReplacedCycles;
}

export function MoveTask<T extends TimeBlock>(inputTasks: T[], command: { taskId: string; startTime: Dayjs }) {
    const taskBeingMoved = getTask(inputTasks, command.taskId);
    const impactingTasks = getImpactingTasks(inputTasks, taskBeingMoved);

    const taskBeingMovedIndex = getTaskIndex(impactingTasks, taskBeingMoved.id);
    const originalTask = cloneDeep(taskBeingMoved);
    const originalStartTime = taskBeingMoved.startTime;
    let newStartTime = command.startTime;

    if (originalStartTime.isSame(newStartTime)) {
        return;
    }

    const isMovingForward = originalStartTime.isBefore(newStartTime);
    const isMovingBackward = originalStartTime.isAfter(newStartTime);

    if (isMovingForward === false) {
        const blockingTime = getMoveBlockingTime(impactingTasks, taskBeingMovedIndex) ?? command.startTime;
        newStartTime = blockingTime.isBefore(newStartTime) ? newStartTime : blockingTime;
    }

    MoveTaskTo(taskBeingMoved, newStartTime);

    let priorLastTask = taskBeingMoved;
    let priorOriginalStart = originalStartTime;

    for (let i = taskBeingMovedIndex - 1; i >= 0; i--) {
        const task = impactingTasks[i];

        const isTaskTouching = task.endTime.isSame(priorOriginalStart);
        priorOriginalStart = task.startTime;

        if (isMovingForward && isTaskTouching) {
            PullTaskForwardTo(task, priorLastTask.startTime);
        } else if (isMovingBackward && isTaskTouching) {
            PushTaskBackwardTo(task, priorLastTask.startTime);
        }

        priorLastTask = task;
    }

    let tempOriginal = originalTask;
    let tempUpdated = taskBeingMoved;
    for (let i = taskBeingMovedIndex + 1; i < impactingTasks.length; i++) {
        const task = impactingTasks[i];

        const newOriginalTask = cloneDeep(task);
        applyInfluenceOnSubsequentTask(tempOriginal, tempUpdated, task);

        tempOriginal = newOriginalTask;
        tempUpdated = task;
    }

    return inputTasks;
}

export function BumpSubsequentTasks<T extends TimeBlock>(inputTasks: T[], taskId: string) {
    const taskBeingMoved = getTask(inputTasks, taskId);
    const impactingTasks = getImpactingTasks(inputTasks, taskBeingMoved);

    const taskBeingMovedIndex = getTaskIndex(impactingTasks, taskBeingMoved.id);

    if(taskBeingMovedIndex === impactingTasks.length - 1)
        return;

    pushSubsequentTasksForward(impactingTasks, taskBeingMovedIndex);
}

export function DeleteTask(inputTasks: ClientTaskModel[], command: DeleteTaskCommand): ClientTaskModel[] {
    const taskBeingDeleted = getTask(inputTasks, command.plannedTaskId);
    let taskBeingDeletedIndex = getTaskIndex(inputTasks, taskBeingDeleted.id);

    const previousTask = getPrevious(inputTasks, taskBeingDeletedIndex);
    const nextTask = getNext(inputTasks, taskBeingDeletedIndex);


    if (isMiddleOfSplit(inputTasks, taskBeingDeletedIndex)) {
        inputTasks.splice(taskBeingDeletedIndex, 2);
        ChangeTaskDuration(previousTask!, previousTask!.durationMinutes + nextTask!.durationMinutes);

        for (let i = taskBeingDeletedIndex; i < inputTasks.length; i++) {
            const task = inputTasks[i];

            MoveTaskBy(task, -1 * taskBeingDeleted.durationMinutes);
        }
    } else if (isFirstOfSplit(inputTasks, taskBeingDeletedIndex)) {
        inputTasks.splice(taskBeingDeletedIndex, 2);
    } else if (isLastOfSplit(inputTasks, taskBeingDeletedIndex)) {
        inputTasks.splice(taskBeingDeletedIndex - 1, 2);
        taskBeingDeletedIndex = taskBeingDeletedIndex - 1;
    } else {
        inputTasks.splice(taskBeingDeletedIndex, 1);

        if (IsFloatDelay(taskBeingDeleted)) {
            for (let i = taskBeingDeletedIndex; i < inputTasks.length; i++) {
                const task = inputTasks[i];

                MoveTaskBy(task, -1 * taskBeingDeleted.durationMinutes);
            }
        }
    }

    return inputTasks;
}

export function DeleteAdHocTask(inputTasks: ClientTaskModel[], command: DeleteAdHocTaskCommand): ClientTaskModel[] {
    const taskBeingDeleted = getTask(inputTasks, command.plannedTaskId);
    let taskBeingDeletedIndex = getTaskIndex(inputTasks, taskBeingDeleted.id);

    const previousTask = getPrevious(inputTasks, taskBeingDeletedIndex);
    const nextTask = getNext(inputTasks, taskBeingDeletedIndex);

    if (isMiddleOfSplit(inputTasks, taskBeingDeletedIndex)) {
        inputTasks.splice(taskBeingDeletedIndex, 2);
        ChangeTaskDuration(previousTask!, previousTask!.durationMinutes + nextTask!.durationMinutes);
    } else if (isFirstOfSplit(inputTasks, taskBeingDeletedIndex)) {
        inputTasks.splice(taskBeingDeletedIndex, 2);
    } else if (isLastOfSplit(inputTasks, taskBeingDeletedIndex)) {
        inputTasks.splice(taskBeingDeletedIndex - 1, 2);
        taskBeingDeletedIndex = taskBeingDeletedIndex - 1;
    } else {
        inputTasks.splice(taskBeingDeletedIndex, 1);
    }

    return inputTasks;
}

export function GetTasksEitherSideOfTimePoint(timePoint: dayjs.Dayjs, tasks: ClientTaskModel[]): { priorTask: ClientTaskModel | null, subsequentTask: ClientTaskModel | null } {
    let priorTask: ClientTaskModel | null = null;
    let subsequentTask: ClientTaskModel | null = null;

    for (let i = 0; i < tasks.length; i++) {
        const task = tasks[i];

        if(task.startTime.isSameOrBefore(timePoint)) {
            if(task.endTime.isAfter(timePoint))
                throw new Error(`Provided time point ${timePoint} lies within task: ${task.id}`);

            priorTask = task;
        }

        if(task.startTime.isSameOrAfter(timePoint)) {
            subsequentTask = task;
            break;
        }
    }

    return {
        priorTask,
        subsequentTask
    };
}

export function AddNewTaskAtTimePoint(
    taskTypes: TaskTypeViewModel[],
    blastPacketTargets: ClientRowBlastPacketTargetDisplayInformation[],
    location: { id: string, name: string | null },
    departmentId: string,
    inputTasks: ClientTaskModel[],
    command: AddTaskAtTimePointCommand
) {
    const newTaskInfo = command.newTask;
    if (newTaskInfo === null) throw new Error('Command does not contain any information about the new task');

    const taskType = taskTypes.find((x) => x.id === newTaskInfo.taskTypeId);
    if (taskType === undefined) throw new Error(`Could not find task task type for new task by id ${newTaskInfo.taskTypeId}`);

    const { priorTask, subsequentTask } = GetTasksEitherSideOfTimePoint(command.startTime, inputTasks);

    const newTask = createNewTask(blastPacketTargets, {
        newPlannedTaskId: newTaskInfo.newPlannedTaskId!,
        startTime: command.startTime,
        newTaskPlannedCycleId: command.plannedCycleId!,
        taskType: taskType,
        durationMinutes: newTaskInfo.durationMinutes,
        departmentId: departmentId,
        locationId: location.id,
        locationName: location.name,
        quantity: newTaskInfo.quantity,
        blastPacketRingTargetId: newTaskInfo.blastPacketRingTargetId
    });

    if(subsequentTask == null)
        inputTasks.push(newTask);
    else {
        const subsequentTaskIndex = getTaskIndex(inputTasks, subsequentTask.id);
        inputTasks.splice(subsequentTaskIndex, 1, newTask, subsequentTask);
        pushSubsequentTasksForward(inputTasks, subsequentTaskIndex);
    }

    return inputTasks;
}

export function AddNewTaskRelativeToExisting(
    taskTypes: TaskTypeViewModel[],
    blastPacketTargets: ClientRowBlastPacketTargetDisplayInformation[],
    inputTasks: ClientTaskModel[],
    command: AddTaskBeforeOrAfterCommand
) {
    const spawnTask = getTask(inputTasks, command.plannedTaskId);

    const newTaskInfo = command.newTask;
    if (newTaskInfo === null) throw new Error('Command does not contain any information about the new task');

    const taskType = taskTypes.find((x) => x.id === newTaskInfo.taskTypeId);
    if (taskType === undefined) throw new Error(`Could not find task task type for new task by id ${newTaskInfo.taskTypeId}`);

    const newTaskStartTime = command.isBefore ? spawnTask.startTime : spawnTask.endTime;

    const newTask = createNewTask(blastPacketTargets, {
        newPlannedTaskId: newTaskInfo.newPlannedTaskId!,
        startTime: newTaskStartTime,
        newTaskPlannedCycleId: command.plannedCycleId!,
        taskType: taskType,
        durationMinutes: newTaskInfo.durationMinutes,
        departmentId: spawnTask.departmentId,
        locationId: spawnTask.locationId,
        locationName: spawnTask.locationName,
        quantity: newTaskInfo.quantity,
        blastPacketRingTargetId: newTaskInfo.blastPacketRingTargetId
    });

    const spawnTaskIndex = getTaskIndex(inputTasks, spawnTask.id);

    if (command.isBefore) {
        inputTasks.splice(spawnTaskIndex, 1, newTask, spawnTask);
    } else {
        inputTasks.splice(spawnTaskIndex, 1, spawnTask, newTask);
    }

    const startingIndex = command.isBefore ? spawnTaskIndex : spawnTaskIndex + 1;
    pushSubsequentTasksForward(inputTasks, startingIndex);

    return inputTasks;
}

function createNewTask(
    blastPacketTargets: ClientRowBlastPacketTargetDisplayInformation[],
    taskInformation: {
        newPlannedTaskId: string,
        newTaskPlannedCycleId: string,
        locationId: string,
        locationName: string | null,
        departmentId: string | null | undefined,
        startTime: dayjs.Dayjs,
        durationMinutes: number,
        taskType: TaskTypeViewModel,
        blastPacketRingTargetId: string | null | undefined,
        quantity: number | null | undefined
    }
) {
    const newTaskEndTime = taskInformation.startTime.add(taskInformation.durationMinutes, 'minutes');

    const newTask = {
        id: taskInformation.newPlannedTaskId,
        isAdHoc: false,
        durationMinutes: taskInformation.durationMinutes,
        locationId: taskInformation.locationId,
        locationName: taskInformation.locationName,
        departmentId: taskInformation.departmentId,
        taskType: taskInformation.taskType,
        taskTypeId: taskInformation.taskType.id,
        plannedCycleId: taskInformation.newTaskPlannedCycleId,
        plannedEquipment: [],
        startTime: taskInformation.startTime,
        endTime: newTaskEndTime,
        equipmentAssignedOn: dayjs.utc(),
        isAutoSplit: false,
        isManualSplit: false,
        correlationId: undefined,
        primaryEquipment: null,
        isDeleted: false,
        offset: 0,
        error: null,
        errors: [],
        conflict: false,
        warnings: [],
        comments: [],
        actualPhysicals: [],
        taskName: null,
        overriddenLocationName: null,
        isCompleted: false,
        priority: 0,
        consumables: null,
        description: null,
        lockedInPast: false,
        differsFromConfig: false,
        blastPacketRingTargetId: taskInformation.blastPacketRingTargetId,
        isFire: false,
        ratePerHour: undefined,
        quantity: taskInformation.quantity != null ? RoundTaskQuantity(taskInformation.quantity) : undefined,
        taskCategoryId: undefined
    } as ClientTaskModel;

    if(taskInformation.quantity != null)
        CalculateRateTaskRate(newTask);

    if(taskInformation.blastPacketRingTargetId) {
        const blastPacketInfo = blastPacketTargets.find(bpt=>bpt.targetId ===taskInformation.blastPacketRingTargetId);

        newTask.blastPacketDisplayInformation = extractBlastPacketDisplayInformation(blastPacketInfo?.ringName, blastPacketInfo?.blastPacketName, taskInformation.locationName);
    }

    return newTask;
}

export function AddNewAdHocTask(
    taskTypes: TaskTypeViewModel[],
    inputTasks: ClientTaskModel[],
    command: CreateAdHocTaskCommand
) {
    const taskType = taskTypes.find((x) => x.id === command.taskTypeId);
    if (taskType === undefined) throw new Error(`Could not find task task type for Adhoc add by ID ${command.taskTypeId}`);

    const startTime = dayjs(command.startTime);
    const endTime = dayjs(command.startTime).add(command.durationMinutes, 'minutes');

    const newAdHocTask: ClientTaskModel = {
        id: command.id,
        taskTypeId: command.taskTypeId,
        isAdHoc: true,
        startTime: startTime,
        endTime: endTime,
        taskName: command.taskName,
        durationMinutes: command.durationMinutes,
        priority: command.priority,
        overriddenLocationName: command.overriddenLocationName,
        locationId: command.locationId!,
        departmentId: command.departmentId,
        consumables: command.consumables,
        description: command.description,
        taskType: taskType,
        plannedEquipment: [],
        equipmentAssignedOn: dayjs.utc(),
        locationName: null,
        plannedCycleId: undefined,
        isAutoSplit: false,
        isManualSplit: false,
        correlationId: undefined,
        primaryEquipment: null,
        isDeleted: false,
        offset: 0,
        error: null,
        errors: [],
        conflict: false,
        warnings: [],
        comments: [],
        isCompleted: false,
        lockedInPast: false,
        differsFromConfig: false,
        blastPacketRingTargetId: undefined,
        isFire: false,
        ratePerHour: undefined,
        quantity: undefined,
        taskCategoryId: command.taskCategoryId
    };

    inputTasks.push(newAdHocTask as any);

    return inputTasks;
}

export function UpdateAdHocTask(
    taskTypes: TaskTypeViewModel[],
    inputTasks: ClientTaskModel[],
    command: UpdateAdHocTaskCommand
): ClientTaskModel[] {
    const editingTask = getTask(inputTasks, command.id);

    const taskType = taskTypes.find((x) => x.id === command.taskTypeId);
    if (taskType === undefined) throw new Error(`Could not find task task type for Adhoc add by ID ${command.taskTypeId}`);

    editingTask.taskTypeId = command.taskTypeId;
    editingTask.taskCategoryId = command.taskCategoryId;
    editingTask.taskName = command.taskName;
    editingTask.overriddenLocationName = command.overriddenLocationName;
    editingTask.priority = command.priority;
    editingTask.consumables = command.consumables;
    editingTask.description = command.description;
    editingTask.taskType = taskType;

    return inputTasks;
}

export function SplitTask(taskTypes: TaskTypeViewModel[], inputTasks: ClientTaskModel[], command: SplitTaskCommand) {
    const taskBeingSplit = getTask(inputTasks, command.plannedTaskId);
    const delayTaskType = taskTypes.find((x) => x.id === command.delayTaskTypeId);
    if (delayTaskType === undefined) throw new Error(`Could not find task task type for split by ID ${command.delayTaskTypeId}`);

    if (taskBeingSplit.durationMinutes < 2 * TIME_UNIT_MINUTES) {
        throw new Error(`Cannot split a task if it is not at least two units long`);
    }

    const originalDuration = taskBeingSplit.durationMinutes;
    const halfTheDuration = originalDuration / 2;
    const numberOfUnitsForHalfDuration = Math.floor(halfTheDuration / TIME_UNIT_MINUTES);
    const splitTime = taskBeingSplit.startTime.add(numberOfUnitsForHalfDuration * TIME_UNIT_MINUTES, 'minutes');

    splitTask(
        inputTasks,
        taskBeingSplit,
        delayTaskType,
        { startTime: splitTime, endTime: splitTime.add(command.splitDuration, 'minutes') },
        {
            delayTask: command.delayPlannedTaskId,
            secondFragment: command.secondHalfPlannedTaskId,
        }
    );

    const taskBeingSplitIndex = getTaskIndex(inputTasks, taskBeingSplit.id);
    pushSubsequentTasksForward(inputTasks, taskBeingSplitIndex + 2);

    return inputTasks;
}

export function SplitAdHocTask(
    taskTypes: TaskTypeViewModel[],
    inputTasks: ClientTaskModel[],
    command: SplitAdhocTaskCommand
) {
    const taskBeingSplit = getTask(inputTasks, command.plannedTaskId);
    const delayTaskType = taskTypes.find((x) => x.id === command.delayTaskTypeId);
    if (delayTaskType === undefined) throw new Error(`Could not find task task type for split by ID ${command.delayTaskTypeId}`);

    if (taskBeingSplit.durationMinutes < 2 * TIME_UNIT_MINUTES) {
        throw new Error(`Cannot split a task if it is not at least two units long`);
    }

    const originalDuration = taskBeingSplit.durationMinutes;
    const halfTheDuration = originalDuration / 2;
    const numberOfUnitsForHalfDuration = Math.floor(halfTheDuration / TIME_UNIT_MINUTES);
    const splitTime = taskBeingSplit.startTime.add(numberOfUnitsForHalfDuration * TIME_UNIT_MINUTES, 'minutes');

    if (taskBeingSplit.correlationId === undefined || taskBeingSplit.correlationId === null) {
        taskBeingSplit.correlationId = v4();
    }

    splitTask(
        inputTasks,
        taskBeingSplit,
        delayTaskType,
        { startTime: splitTime, endTime: splitTime.add(command.splitDuration, 'minutes') },
        {
            delayTask: command.delayPlannedTaskId,
            secondFragment: command.secondHalfPlannedTaskId,
        }
    );

    return inputTasks;
}

export function MoveTaskToNextShift(
    inputTasks: ClientTaskModel[],
    command: { taskId: string; nextShiftStartTime: Dayjs }
) {
    const taskToMove = getTask(inputTasks, command.taskId);
    const impactingTasks = getImpactingTasks(inputTasks, taskToMove);

    const taskToMoveIndex = getTaskIndex(impactingTasks, taskToMove.id);
    const previousTask = getPrevious(impactingTasks, taskToMoveIndex);

    MoveTaskTo(taskToMove, command.nextShiftStartTime);

    if (previousTask) {
        PullTaskForwardTo(previousTask, taskToMove.startTime);
    }

    pushSubsequentTasksForward(impactingTasks, taskToMoveIndex);

    return inputTasks;
}

export function BringTaskForward(inputTasks: ClientTaskModel[], command: UpdateTaskTimesCommand) {
    const taskBeingBroughtForward = getTask(inputTasks, command.times[0].id);
    const taskBeingBroughtForwardIndex = getTaskIndex(inputTasks, taskBeingBroughtForward.id);

    // @ts-ignore
    const startTime = dayjs(command.times[0].start).utc();
    // get any time blocking us, but don't go prior to whatever was specified in the command
    const blockingTime = getMoveBlockingTime(inputTasks, taskBeingBroughtForwardIndex, startTime) ?? startTime;

    MoveTaskTo(taskBeingBroughtForward, blockingTime);

    for (let i = taskBeingBroughtForwardIndex - 1; i >= 0; i--) {
        const task = inputTasks[i];

        if (task.endTime.isSameOrBefore(blockingTime)) break;

        PushTaskBackwardTo(task, blockingTime);
    }

    let lastTaskForSubsequent = taskBeingBroughtForward;
    for (let i = taskBeingBroughtForwardIndex + 1; i < inputTasks.length; i++) {
        const task = inputTasks[i];

        if (task.plannedCycleId !== command.plannedCycleId) break;

        MoveTaskTo(task, lastTaskForSubsequent.endTime);

        if (IsFloatDelay(task)) {
            ChangeTaskDuration(task, 0);
        }

        lastTaskForSubsequent = task;
    }

    return inputTasks;
}

export function CollapseTimePeriods(periods: TimePeriod[]): TimePeriod[] {
    const occupiedTimes: TimePeriod[] = [];
    let workingOccupiedTime: TimePeriod | null = null;

    for(const childPeriod of OrderTasks([...periods])) {
        if(workingOccupiedTime == null)
            workingOccupiedTime = {
                startTime: childPeriod.startTime,
                endTime: childPeriod.endTime,
                durationMinutes: childPeriod.durationMinutes
            };
        else{
            if(childPeriod.startTime.isSameOrBefore(workingOccupiedTime.endTime))
                workingOccupiedTime= {
                    startTime: workingOccupiedTime.startTime,
                    endTime: childPeriod.endTime.isSameOrAfter(workingOccupiedTime.endTime) ? childPeriod.endTime : workingOccupiedTime.endTime,
                    durationMinutes: childPeriod.endTime.isSameOrAfter(workingOccupiedTime.endTime) ? childPeriod.endTime.diff(workingOccupiedTime.startTime, 'minutes') : workingOccupiedTime.durationMinutes
                };
            else{
                occupiedTimes.push(workingOccupiedTime);
                workingOccupiedTime = {
                    startTime: childPeriod.startTime,
                    endTime: childPeriod.endTime,
                    durationMinutes: childPeriod.durationMinutes
                };
            }
        }
    }

    if(workingOccupiedTime != null)
        occupiedTimes.push(workingOccupiedTime);

    return occupiedTimes;
}

export function OrderTasks<T extends TimePeriod>(tasks: T[]): T[] {
    return tasks.sort((a, b) => {
        if (a.startTime.isSame(b.startTime)) {
            if (a.durationMinutes === 0 && b.durationMinutes !== 0) return -1;
            else if (a.durationMinutes !== 0 && b.durationMinutes === 0) return 1;
            else return 0;
        }

        return a.startTime.diff(b.startTime);
    });
}

enum AutosplitOwnershipTransferState {
    Initial,
    Replacing
}

function transferOwnershipOfFollowingAutosplits(inputTasks: ClientTaskModel[], startFromIndex: number, startingCorrelationId: string | null, returnTasksToRemoveInsteadOfDeleting: boolean, allowPreviousTaskToHaveNoCorrelationId: boolean = false): string[] {
    if(startingCorrelationId == null)
        return [];

    if(inputTasks[startFromIndex] == null)
        return [];

    if(startFromIndex == 0 || (inputTasks[startFromIndex - 1].correlationId !== inputTasks[startFromIndex].correlationId && !allowPreviousTaskToHaveNoCorrelationId))
        return []; // there's no point transferring ownership if we must be either not in a correlated block or the VERY start of it

    const tasksRequiringRemoval: string[] = [];

    const replacingCorrelationId = v4();
    let autosplitOwnershipTransferState = AutosplitOwnershipTransferState.Initial;

    for(let i = startFromIndex; i < inputTasks.length; i++) {
        const task = inputTasks[i];

        if(task.correlationId !== startingCorrelationId)
            break;

        if(autosplitOwnershipTransferState === AutosplitOwnershipTransferState.Initial) {
            if(isMiddleOfAutoSplit(inputTasks, i)) {
                inputTasks[i+1].isAutoSplit = false;
                if(returnTasksToRemoveInsteadOfDeleting)
                    tasksRequiringRemoval.push(task.id);
                else{
                    inputTasks.splice(i, 1);
                    i--;  // jump back to a previous index otherwise we're effectively jumping ahead an extra spot
                }

                continue;
            }

            if(task.correlationId === startingCorrelationId) {
                task.correlationId=replacingCorrelationId;
                autosplitOwnershipTransferState = AutosplitOwnershipTransferState.Replacing;
            }
        } else if(autosplitOwnershipTransferState === AutosplitOwnershipTransferState.Replacing) {
            task.correlationId=replacingCorrelationId;
        }
    }

    return tasksRequiringRemoval;
}

export function SetTaskEquipment(
    equipment: PlannedEquipmentViewModel[],
    inputTasks: ClientTaskModel[],
    command: SetTaskEquipmentCommand
) {
    if (command == null) return inputTasks;

    const taskToUpdate = inputTasks.find((x) => x.id === command.plannedTaskId);

    if (taskToUpdate === undefined) {
        throw new Error(`Cannot find task with id ${command.plannedTaskId}`);
    }

    const equip = equipment.filter((x) => x.id == command.plannedEquipmentId);

    if (equip && equip.length > 0) {
        const chosenEquipment = equip[0];
        const newPrimary = {
            _type: 'PlannedEquipmentModel',
            id: chosenEquipment.id,
            equipmentId: chosenEquipment.equipmentId,
            idleOrUnavailable: chosenEquipment.idleOrUnavailable,
            name: chosenEquipment.equipment?.name ?? '',
            equipmentRole: chosenEquipment.equipment?.equipmentRole ?? null,
            availability: chosenEquipment.availability,
            imageUrl: chosenEquipment.equipment?.imageUrl ?? null,
            showConflicts: chosenEquipment.equipment?.showConflicts ?? true,
            equipmentRoleId: chosenEquipment.equipment?.equipmentRoleId,
            isPrimary: true,
        };

        // @ts-ignore
        taskToUpdate.primaryEquipment = newPrimary;

        if (taskToUpdate.plannedEquipment.findIndex((x) => x.equipmentId === newPrimary.equipmentId) === -1) {
            taskToUpdate.plannedEquipment.splice(0, taskToUpdate.plannedEquipment.length);
            taskToUpdate.plannedEquipment.push({
                ...newPrimary,
                _type: 'ShiftPlannedEquipment',
                equipmentRole: {
                    ... chosenEquipment.equipment!.equipmentRole!
                }
            });
        }
    } else {
        taskToUpdate.primaryEquipment = null;
        taskToUpdate.plannedEquipment = [];
    }

    return inputTasks;
}

// Cleanup splits
// Remove any auto splits and reset time for subsequent tasks to cater for the split removal
// The splits will be re-calculated during ApplyContraints
export function CleanupSplits(inputTasks: ClientTaskModel[]) {
    let numberOfTasks = inputTasks.length - 1;
    for (let index = 0; index <= numberOfTasks; index++) {
        const task = inputTasks[index];

        if (isMiddleOfSplit(inputTasks, index) === false) continue;
        if (task.isManualSplit) continue;

        const firstFragment = inputTasks[index - 1];
        const secondFragment = inputTasks[index + 1];

        let nextFragment = inputTasks[index + 1];
        const relatedFragments = [];
        let counter = 2;
        while (IsRelatedToTask(firstFragment, nextFragment)) {
            relatedFragments.push(nextFragment);
            nextFragment = inputTasks[index + counter];
            counter++;
        }

        const relatedTaskDuration = relatedFragments.reduce((acc, e) => {
            return acc + (IsFloatDelay(e) ? 0 : e.durationMinutes);
        }, 0);
        const relatedTaskQuantity = relatedFragments.reduce((acc, e) => {
            return acc + (IsFloatDelay(e) ? 0 : e.quantity ?? 0);
        },0);
        const relatedTaskCount = 2 + relatedFragments.length;

        if (firstFragment.equipmentAssignedOn.isBefore(secondFragment.equipmentAssignedOn)) {
            firstFragment.primaryEquipment = secondFragment.primaryEquipment;
            firstFragment.plannedEquipment.splice(
                0,
                firstFragment.plannedEquipment.length,
                ...secondFragment.plannedEquipment
            );
        }

        ChangeTaskDuration(firstFragment, firstFragment.durationMinutes + relatedTaskDuration);

        if(IsRateTask(firstFragment))
            ChangeTaskQuantity(firstFragment, (firstFragment.quantity ?? 0) + relatedTaskQuantity, true);

        firstFragment.isAutoSplit = false;
        AddFragmentsToTaskAutoSplitCache(firstFragment, [task, ...relatedFragments]);
        inputTasks.splice(index - 1, relatedTaskCount, firstFragment);
        numberOfTasks = numberOfTasks - (relatedFragments.length + 1);
    }

    return inputTasks;
}

function AddFragmentsToTaskAutoSplitCache(firstFragment: ClientTaskModel, fragmentsToAdd: ClientTaskModel[]) {
    if(firstFragment.cachedAutoSplits == null)
        firstFragment.cachedAutoSplits = fragmentsToAdd.map(frag=>({ taskId: frag.id, taskTypeId: frag.taskTypeId ?? ''}));
    else
        firstFragment.cachedAutoSplits=[...firstFragment.cachedAutoSplits, ...fragmentsToAdd.map(frag=>({ taskId: frag.id, taskTypeId: frag.taskTypeId ?? ''}))];
}

function ResetCachedAutoSplits(inputTasks: ClientTaskModel[]){
    for (let index = 0; index < inputTasks.length; index++) {
        const task = inputTasks[index];
        delete task.cachedAutoSplits;
    }
}

export function ApplyConstraints(
    inputTasks: ClientTaskModel[],
    noGoFinder: NoGoZoneFinder,
    taskTypes: TaskTypeViewModel[],
    departmentEosTask: TaskTypeViewModel | null
) {
    CleanupSplits(inputTasks);
    try {
        let lastTask = null;
        for (let index = 0; index < inputTasks.length; index++) {
            const task = inputTasks[index];
            const originalEndTime = task.endTime;
            const originalStartTime = task.startTime;

            const newTaskLocation = IsEosTask(task, departmentEosTask)
                ? moveTaskToEos(inputTasks, task, noGoFinder)
                : moveTaskOutOfEos(inputTasks, task, taskTypes, noGoFinder);

            if (originalStartTime.isBefore(task.startTime)) {
                if (index !== 0) PullTaskForwardTo(inputTasks[index - 1], task.startTime);
            }

            if (originalEndTime.isBefore(newTaskLocation.endTime) || overlapsNextTask(inputTasks, index, true)) {
                pushSubsequentTasksForward(inputTasks, index);
            }

            if (originalStartTime.isAfter(task.startTime)) {
                if (index !== 0) PushTaskBackwardAtLeastTo(inputTasks[index - 1], task.startTime);
            }

            lastTask = task;
        }

        return inputTasks;
    } finally {
        ResetCachedAutoSplits(inputTasks);
    }
}

export function ValidateLocationName(name: string, convention: string, cycleType: CycleType | null) {
    if(cycleType == null || cycleType == CycleType.AdHoc)
        return true;

    return Locations.validate(convention, name).valid || `Location must be in the format '${convention}'`;
}

function moveTaskToEos(inputTasks: ClientTaskModel[], task: ClientTaskModel, noGoFinder: NoGoZoneFinder) {
    if (IsDelay(task)) return task;
    if (noGoFinder.ignoreEos) return task;

    const taskIndex = inputTasks.findIndex((x) => x.id === task.id);
    const blockingTime = getMoveBlockingTime(inputTasks, taskIndex);

    let eosZone;
    if (blockingTime) {
        eosZone = noGoFinder.getZoneAfter(blockingTime, NoGoType.EndOfShift);
    } else {
        eosZone =
            noGoFinder.getZoneBefore(task.startTime, NoGoType.EndOfShift) ||
            noGoFinder.getZoneAfter(task.startTime, NoGoType.EndOfShift);
    }

    MoveTaskTo(task, eosZone.startTime);

    return task;
}

function moveTaskOutOfEos(
    inputTasks: ClientTaskModel[],
    task: ClientTaskModel,
    taskTypes: TaskTypeViewModel[],
    noGoFinder: NoGoZoneFinder
) {
    if (IsDelay(task)) return task;
    if (noGoFinder.ignoreEos && noGoFinder.ignoreSos) return task;

    let newTask = task;
    let prevSplit: ClientTaskModel | null = null;
    let currentIndex = 0;
    let noGoZone = noGoFinder.getZoneWithIndex(currentIndex);

    while (
        newTask.startTime.isSameOrAfter(noGoZone.endTime) ||
        TaskIntersectsTimespan(task, noGoZone.startTime, noGoZone.endTime)
    ) {
        if (noGoZone.noGoType === NoGoType.EndOfShift && noGoFinder.ignoreEos) {
            currentIndex = currentIndex + 1;
            noGoZone = noGoFinder.getZoneWithIndex(currentIndex);
            continue;
        }

        if (noGoZone.noGoType === NoGoType.StartOfShift && noGoFinder.ignoreSos) {
            currentIndex = currentIndex + 1;
            noGoZone = noGoFinder.getZoneWithIndex(currentIndex);
            continue;
        }

        if (TaskStartsDuring(newTask, noGoZone.startTime, noGoZone.endTime)) {
            MoveTaskTo(newTask, noGoZone.endTime);
            // if our original task overlaps two adjacent zones (say EoS+SoS) we may already have a
            // delay split that should be pulled forwards along with newTask
            if(prevSplit !== null)
                PullTaskForwardTo(prevSplit, noGoZone.endTime);
        } else if (TaskIntersectsTimespan(newTask, noGoZone.startTime, noGoZone.endTime)) {
            let delayType = taskTypes.find((x) => x.taskClass === TaskClasses.Wait && IsFloatDelayType(x));
            if (delayType === undefined) delayType = taskTypes.find((x) => IsFloatDelayType(x));
            if (delayType === undefined) delayType = taskTypes.find((x) => x.id === task.taskType.defaultSplitDelayTaskTypeId);

            if (delayType === undefined) throw new Error('Cannot find suitable split delay');

            const [first, split, second] = splitTask(inputTasks, newTask, delayType, {
                startTime: noGoZone.startTime,
                endTime: noGoZone.endTime,
            }, undefined, true);

            newTask = second;
            prevSplit = split;
        }

        currentIndex = currentIndex + 1;
        noGoZone = noGoFinder.getZoneWithIndex(currentIndex);
    }

    return newTask;
}

function getSplitIds(taskBeingSplit: ClientTaskModel, delayTaskType: TaskTypeViewModel, useAutoSplitCacheIfPresent: boolean, requestedIds? : { delayTask: string | null; secondFragment: string | null }) {
    let cachedInsertedSplitId = null as string | null;

    if(useAutoSplitCacheIfPresent &&
        (taskBeingSplit.cachedAutoSplits?.length ?? 0) > 0
    ){
        const cachedAutoSplit = taskBeingSplit.cachedAutoSplits!.shift();
        cachedInsertedSplitId = (cachedAutoSplit?.taskTypeId == delayTaskType.id ? cachedAutoSplit?.taskId : null) ?? null;
    }

    let cachedSecondFragmentId = null as string | null;

    if(useAutoSplitCacheIfPresent &&
        (taskBeingSplit.cachedAutoSplits?.length ?? 0) > 0
    ){
        const cachedAutoSplit = taskBeingSplit.cachedAutoSplits!.shift();
        cachedSecondFragmentId = (cachedAutoSplit?.taskTypeId == taskBeingSplit.taskTypeId ? cachedAutoSplit?.taskId : null) ?? null;
    }

    if(cachedInsertedSplitId == null || cachedSecondFragmentId == null){
        delete taskBeingSplit.cachedAutoSplits;
        cachedInsertedSplitId = null;
        cachedSecondFragmentId = null;
    }

    return [
        requestedIds?.delayTask ?? cachedInsertedSplitId ?? v4(),
        requestedIds?.secondFragment ?? cachedSecondFragmentId ?? v4()
    ]
}

function splitTask(
    inputTasks: ClientTaskModel[],
    taskBeingSplit: ClientTaskModel,
    delayTaskType: TaskTypeViewModel,
    splitTimespan: Timespan,
    ids?: { delayTask: string | null; secondFragment: string | null },
    useAutoSplitCacheIfPresent: boolean = false
) {
    const originalDuration = taskBeingSplit.durationMinutes;

    if (taskBeingSplit.correlationId === undefined || taskBeingSplit.correlationId === null) {
        taskBeingSplit.correlationId = v4();
    }

    const [insertedSplitId, secondFragmentId] = getSplitIds(taskBeingSplit, delayTaskType, useAutoSplitCacheIfPresent, ids);

    const firstFragment: ClientTaskModel = {
        ...taskBeingSplit,
        isAutoSplit: ids === undefined,
        endTime: splitTimespan.startTime,
        durationMinutes: splitTimespan.startTime.diff(taskBeingSplit.startTime, 'minutes'),
    };

    const firstFragmentProportion = firstFragment.durationMinutes / originalDuration;
    const firstFragmentQuantity = firstFragment.quantity != null ? RoundTaskQuantity(firstFragmentProportion * firstFragment.quantity) : null;
    const secondFragmentQuantity = firstFragmentQuantity != null ? firstFragment.quantity! - firstFragmentQuantity : null;

    firstFragment.quantity = firstFragmentQuantity;

    const insertedSplit: ClientTaskModel = {
        ...taskBeingSplit,
        id: insertedSplitId,
        primaryEquipment: null,
        startTime: splitTimespan.startTime,
        durationMinutes: splitTimespan.endTime.diff(splitTimespan.startTime, 'minutes'),
        endTime: splitTimespan.endTime,
        taskType: delayTaskType,
        taskTypeId: delayTaskType.id,
        isManualSplit: ids !== undefined,
        plannedEquipment: [],
        ratePerHour: null,
        quantity: null,
        isDelay: true,
        isFloatable: true
    };

    const secondFragmentStartTime = splitTimespan.endTime;
    const secondFragmentDuration = originalDuration - firstFragment.durationMinutes;
    const secondFragment: ClientTaskModel = {
        ...taskBeingSplit,
        id: secondFragmentId,
        startTime: secondFragmentStartTime,
        durationMinutes: secondFragmentDuration,
        endTime: secondFragmentStartTime.add(secondFragmentDuration, 'minutes'),
        isAutoSplit: ids === undefined,
        quantity: secondFragmentQuantity
    };

    if(IsRateTask(firstFragment)) {
        CalculateRateTaskRate(firstFragment);
        CalculateRateTaskRate(secondFragment);
    }

    const taskBeingSplitIndex = inputTasks.findIndex((x) => x.id === taskBeingSplit.id);
    inputTasks.splice(taskBeingSplitIndex, 1, firstFragment, insertedSplit, secondFragment);

    return [firstFragment, insertedSplit, secondFragment];
}

function overlapsNextTask(inputTasks: ClientTaskModel[], taskIndex: number, ignoreAdhocs: boolean = false) {
    if (taskIndex >= inputTasks.length - 1) return false;

    const task = inputTasks[taskIndex];

    if(task.isAdHoc && ignoreAdhocs)
        return false;

    const nextTask = inputTasks[taskIndex + 1];

    return TaskStartsDuring(nextTask, task.startTime, task.endTime);
}

function pushSubsequentTasksForward<T extends TimeBlock>(inputTasks: T[], startIndex: number) {
    let lastTask = inputTasks[startIndex];

    for (let i = startIndex + 1; i < inputTasks.length; i++) {
        const task = inputTasks[i];

        const newStartTime = lastTask.endTime;
        if (lastTask.endTime.isAfter(task.startTime) && ((!task.isAdHoc && !lastTask.isAdHoc) || IsRelatedToTask(task, lastTask))) {
            PushTaskForwardTo(task, newStartTime);
        }

        lastTask = task;
    }
}

function getImpactingTasks<T extends TimeBlock>(inputTasks: T[], task: T): T[] {
    if (task.isAdHoc === false) return inputTasks;
    return inputTasks.filter((x) => task.id === x.id || (task.correlationId && task.correlationId === x.correlationId));
}

function getTask<T extends TimeBlock>(inputTasks: T[], taskId: string | null): T {
    if (taskId === null) throw new Error('Task id is null');

    const task = inputTasks.find((x) => x.id === taskId);
    if (task === undefined) throw new Error(`Cannot find task with id ${taskId}`);

    return task;
}

function getTaskIndex<T extends TimeBlock>(inputTasks: T[], taskId: string | null): number {
    if (taskId === null) throw new Error('Task id is null');

    const taskIndex = inputTasks.findIndex((x) => x.id === taskId);
    if (taskIndex === -1) throw new Error(`Cannot find index for task with id ${taskId}`);

    return taskIndex;
}

function getMoveBlockingTime<T extends TimeBlock>(inputTasks: T[], movingTaskIndex: number, noEarlierThanTime: dayjs.Dayjs | null = null): Dayjs | null {
    for (let i = movingTaskIndex - 1; i >= 0; i--) {
        const task = inputTasks[i];
        if(noEarlierThanTime != null && task.endTime.isBefore(noEarlierThanTime))
            return null;
        if (!IsMoveBlocking(task)) continue;

        return task.endTime;
    }

    return null;
}

export function isFirstOfSplit(tasks: ClientTaskModel[], indexOfTask: number): boolean {
    const task = tasks[indexOfTask];
    if (IsFloatDelay(task)) return false;

    const nextTask = getNext(tasks, indexOfTask);
    if (nextTask === null) return false;

    const taskAfterNext = getNext(tasks, indexOfTask + 1);
    if (taskAfterNext === null) return false;

    return IsRelatedToTask(task, nextTask) && IsRelatedToTask(task, taskAfterNext);
}

export function isMiddleOfSplit(tasks: (ClientTaskModel | WeekTask)[], indexOfTask: number): boolean {
    const task = tasks[indexOfTask];
    if (IsFloatDelay(task) === false) return false;

    const previousTask = getPrevious(tasks, indexOfTask);
    if (previousTask === null) return false;

    const nextTask = getNext(tasks, indexOfTask);
    if (nextTask === null) return false;

    return IsRelatedToTask(previousTask, nextTask);
}

export function isFirstOfAutoSplit(tasks: ClientTaskModel[], indexOfTask: number): boolean {
    const task = tasks[indexOfTask];
    if (IsFloatDelay(task)) return false;

    const nextTask = getNext(tasks, indexOfTask);
    if (nextTask === null) return false;

    if (IsFloatDelay(nextTask) === false || nextTask.isManualSplit) return false;

    const taskAfterNext = getNext(tasks, indexOfTask + 1);
    if (taskAfterNext === null) return false;

    return IsRelatedToTask(task, nextTask) && IsRelatedToTask(task, taskAfterNext);
}

export function isLastOfAutoSplit(tasks: ClientTaskModel[], indexOfTask: number): boolean {
    const task = tasks[indexOfTask];
    if (IsFloatDelay(task)) return false;

    const previousTask = getPrevious(tasks, indexOfTask);
    if (previousTask === null) return false;

    if (IsFloatDelay(previousTask) === false || previousTask.isManualSplit) return false;

    const taskBeforePrevious = getPrevious(tasks, indexOfTask - 1);
    if (taskBeforePrevious === null) return false;

    return IsRelatedToTask(task, previousTask) && IsRelatedToTask(task, taskBeforePrevious);
}

export function isMiddleOfAutoSplit(tasks: ClientTaskModel[], indexOfTask: number): boolean {
    const task = tasks[indexOfTask];
    if(!IsFloatDelay(task) || task.isManualSplit) return false;

    const previousTask = getPrevious(tasks, indexOfTask);
    if(previousTask === null) return false;

    const nextTask = getNext(tasks, indexOfTask);
    if(nextTask === null) return false;

    return IsRelatedToTask(task, previousTask) && IsRelatedToTask(task, nextTask);
}

export function isLastOfSplit(tasks: ClientTaskModel[], indexOfTask: number): boolean {
    const task = tasks[indexOfTask];
    if (IsFloatDelay(task)) return false;

    const previousTask = getPrevious(tasks, indexOfTask);
    if (previousTask === null || IsFloatDelay(previousTask) === false) return false;

    const taskBeforePrevious = getPrevious(tasks, indexOfTask - 1);
    if (taskBeforePrevious === null) return false;

    return IsRelatedToTask(task, previousTask) && IsRelatedToTask(task, taskBeforePrevious);
}

function applyInfluenceOnSubsequentTask<T extends TimeBlock>(actor: Timespan, updatedActor: Timespan, subject: T) {
    const isPush = updatedActor.endTime.isAfter(actor.endTime);
    const isPull = updatedActor.endTime.isBefore(actor.endTime);

    if (isPull) {
        const moveDistance = updatedActor.endTime.diff(actor.endTime, 'minutes');
        const newLocation = subject.startTime.add(moveDistance, 'minutes');
        PullTaskBackwardTo(subject, newLocation);
    } else if (isPush) {
        const areTouching = updatedActor.endTime.isSameOrAfter(subject.startTime);
        if (areTouching) {
            PushTaskForwardTo(subject, updatedActor.endTime);
        }
    }

    return;
}

export function GetDropTimeWithinPeriod(
    xPositionWithinPeriodAsPercentage: number,
    startOfPeriod: dayjs.Dayjs,
    endOfPeriod: dayjs.Dayjs
): dayjs.Dayjs {
    const minutesInShift = endOfPeriod.diff(startOfPeriod, 'minutes');
    const suggestedLandingPointMinutes = TIME_UNIT_MINUTES * Math.floor((minutesInShift * xPositionWithinPeriodAsPercentage / 100) / TIME_UNIT_MINUTES);
    const requestedStartTime = startOfPeriod.add(suggestedLandingPointMinutes, 'minutes');
    return requestedStartTime;
}