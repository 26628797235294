import { defineStore } from 'pinia';
import dayjs, { Dayjs, UnitTypeLong } from 'dayjs';
import ShiftWindowApi from '@/lib/data/ShiftWindowApi';
import { LocationPlannedHighLevelActualsViewModel } from '@/models/api';
import { ShiftDetails } from '@/lib/stores/ShiftWindowStore';
import { useShiftDetails } from '@/lib/stores/Shift/ShiftDetailsStore';

export const useShiftHighLevelActuals = defineStore('shiftHighLevelActuals', {
    state: () => {
        return {
            highLevelActuals: null as null | LocationPlannedHighLevelActualsViewModel[],
            loading: false
        };
    },
    getters: {
        shiftPredictions(): LocationPlannedHighLevelActualsViewModel[] {
            const shiftStartTime = useShiftDetails().shiftStartTime;
            const shiftEndTime = useShiftDetails().shiftEndTime;

            return this.highLevelActuals?.filter((location) => {
                const locationCompletionTime = dayjs(location.completionTime);
                return locationCompletionTime.isSameOrAfter(shiftStartTime) && locationCompletionTime.isSameOrBefore(shiftEndTime);
            }) ?? [];
        }
    },
    actions: {
        async populateWindow(mineId: string, departmentId: string, date: string, shiftNumber: string, offsetPrior: number, offsetFollowing: number) {
            this.loading = true;
            const response = await ShiftWindowApi.getHighLevelActualsWindow(mineId, departmentId, date, shiftNumber, offsetPrior, offsetFollowing);
            this.highLevelActuals = response.locations;
            this.loading = false;
        },
    }
});