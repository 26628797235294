import Vue from 'vue';

Vue.filter('availabilityType', (type: number) => {
    if (type === 0) return 'Leave';
    if (type === 1) return 'Overtime';
    return 'Unknown';
});

Vue.filter('availabilityImportOperation', (op: number) => {
    if (op === 1) return 'Add';
    if (op === 2) return 'Remove';
    return 'Unknown';
});

Vue.filter('appEventType', (op: string | number) => {
    if (op === 0 || op == 'CreateShift') return 'Shift Created';
    if (op === 1 || op == 'StartMeeting') return 'Meeting Started';
    if (op === 2 || op == 'EndMeeting') return 'Meeting Ended';
    if (op === 3 || op == 'LocationAdded') return 'Location Added';
    if (op === 4 || op == 'PublishShift') return 'Shift Published';
    if (op === 101 || op == 'ShiftOwnerAssigned') return 'Shift Owner Assigned';
    if (op === 102 || op == 'ShiftOwnerHandover') return 'Shift Owner Handover';
    if (op === 103 || op == 'ShiftOwnerTakeOwnership') return 'Shift Ownership Taken';
    return 'Unknown';
});

Vue.filter('equipmentAvailabilityType', (type: number) => {
    if (type === 0) return 'Weekly';
    if (type === 1) return 'Minor';
    if (type === 2) return 'Major';
    return 'Unknown';
});

Vue.filter('assignShiftOwnerActionType', (type: number) => {
    if (type === 1) return 'Assign';
    if (type === 2) return 'Handover';
    if (type === 3) return 'Take Ownership';
    return 'Unknown';
});

Vue.filter('cycleType', (type: number) => {
    if (type === 0) return 'Duration-based';
    if (type === 1) return 'Rate-based';
    if (type === 2) return 'Ad-hoc Tasks';
    return 'Unknown';
});

Vue.filter('miningMethod', (type: number) => {
    if (type === 0) return 'Stoping';
    if (type === 1) return 'Sub Level Caving (SLC)';
    return 'Unknown';
});

Vue.filter('locationStatus', (status: number) => {
    if (status === 0) return 'Future';
    if (status === 1) return 'Current';
    if (status === 2) return 'On Hold';
    if (status === 4) return 'Completed';
    if (status === 5) return 'Archived';
    return 'Unknown';
});

Vue.filter('locationStatusOrder', (status: number) => {
    if (status === 1) return 0;
    if (status === 2) return 1;
    if (status === 0) return 2;
    if (status === 4) return 3;
    if (status === 5) return 4;
    return 5;
});

Vue.filter('definitionType', (type: number) => {
    if (type === 0) return 'Set Values';
    if (type === 1) return 'Text';
    return 'Unknown';
});

Vue.filter('stockpileStatus', (status: number) => {
    if (status == 0) return 'Empty';
    if (status == 1) return '1/4 Full';
    if (status == 2) return '1/2 Full';
    if (status == 3) return '3/4 Full';
    if (status == 4) return 'Full';
    return '';
});

Vue.filter('ringStatus', (type: number) => {
    if (type === 0) return 'Not Started';
    if (type === 1) return 'Drilling';
    if (type === 2) return 'Drilled';
    if (type == 3) return 'Charging';
    if (type == 4) return 'Charged';
    if (type == 5) return 'Fired';
    if (type == 6) return 'Lashing';
    if (type == 7) return 'Lashed';
});

Vue.filter('externalNotificationEventType', (type: number) => {
    if (type === 0) return 'All';
    if (type === 1) return 'Shift Pack';
    if (type === 2) return 'Shift not Planned';
    if (type === 3) return 'Variance Report';
    if (type === 4) return 'No Shift Pack Generated';
});

Vue.filter('externalNotificationEventTypeDescription', (type: number) => {
    if (type === 0) return 'All Events';
    if (type === 1) return 'Get notified each shift with the latest printed shift pack for that shift.';
    if (type === 2) return 'Get notified if the current shift has not started planning.';
    if (type === 3) return 'Get notified each day with the latest 24 hour variance report if one exists.';
    if (type === 4) return 'Get notified if no shift pack exists for the current shift.';
});

Vue.filter('externalNotificationEventTypeOrder', (type: number) => {
    if (type === 0) return 0;
    if (type === 1) return 1;
    if (type === 2) return 3;
    if (type === 3) return 4;
    if (type === 4) return 2;
});

Vue.filter('temperatureScaleName', (type: number) => {
    if (type === 0) return 'Celsius';
    if (type === 1) return 'Fahrenheit';
    if (type === 2) return 'Kelvin';
    return 'Unknown';
});

Vue.filter('temperatureScaleSymbol', (type: number) => {
    if (type === 0) return '°C';
    if (type === 1) return '°F';
    if (type === 2) return 'K';
    return 'Unknown';
});

Vue.filter('commsFieldStickiness', (type: number) => {
    if(type === 0) return 'Reset each shift or week';
    if(type === 1) return 'Generally the same for each crew';
    if(type === 2) return 'Persists across shifts or weeks';
    return 'Unknown';
});

Vue.filter('commsFieldStickinessDescription', (type: number) => {
    if(type === 0) return 'Is not copied from previous shifts or weeks';
    if(type === 1) return 'Can be autopopulated from previous shifts with the same crew';
    if(type === 2) return 'Is copied from previous shifts or weeks';
    return 'Unknown';
});

Vue.filter('commsFieldTemporalLevel', (type: number) => {
    if(type === 0) return 'Shift';
    if(type === 1) return 'Week';
    return 'Unknown';
});

Vue.filter('commsFieldDataType', (type: number) => {
    if(type === 0) return 'Free Text';
    if(type === 1) return 'Role';
    return 'Unknown';
});

Vue.filter('commsFieldDataTypeDescription', (type: number) => {
    if(type === 0) return 'Any value can be entered';
    if(type === 1) return 'Any value can be entered, but is also eligible for auto-population from staff';
    return 'Unknown';
});

Vue.filter('intendedBoardUses', (type: number) => {
    if(type === 0) return 'Development';
    if(type === 1) return 'Production';
    if(type === 2) return 'Adhoc/Services';
    return 'Unknown';
});

Vue.filter('savedStatuses', (type: number) => {
    if(type === 0) return 'New';
    if(type === 1) return 'Modified';
    if(type === 2) return 'Saving...';
    if(type === 3) return 'Saved';
    return '';
});

Vue.filter('savedStatusColours', (type: number) => {
    if(type === 0) return 'gray';
    if(type === 1) return 'warning';
    if(type === 2) return 'primary';
    if(type === 3) return 'green';
    return 'gray';
});