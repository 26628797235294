import AppHttp from "@/lib/AppHttp";
import {UpdateActualsCommand} from "@/models/api/Commands/update-actuals-command";
import {UpsertActualsDefinitionCommand, UpsertActualsGroupCommand} from "@/models/api";

export class Actuals {
    private resource: string = 'Actuals';
    
    public async getFilterOptionsForDepartmentShiftId(shiftId: string) {
        const response = await AppHttp.get(`${this.resource}/GetFilterOptionsForDepartmentShift?departmentShiftId=${shiftId}`);

        if (!response || !response.data)
            return [];
        else
            return response.data;
    }

    public async upsertDefinition(command: UpsertActualsDefinitionCommand) {
        const response = await AppHttp.put(`${this.resource}/definitions`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async upsertGroup(command: UpsertActualsGroupCommand) {        
        const response = await AppHttp.put(`${this.resource}/groups`, command);
                
        const data = response ? response.data : null;
        return data;
    }

    public async getGroups(taskTypeIds: string[]): Promise<any[]> {
        const response = await AppHttp.post(`${this.resource}/Groups`, {
            onlyForTaskTypeIds: taskTypeIds
        });

        if (!response || !response.data)
            return [];
        else
            return response.data.actualsGroups;
    }
    
    public async deleteDefinition(definitionId: string) {
        const response = await AppHttp.delete(`${this.resource}/Definitions/${definitionId}`);
        return response;
    }

    public async deleteGroup(groupId: string) {
        const response = await AppHttp.delete(`${this.resource}/Groups/${groupId}`);
        return response;
    }
    
    public async getDefinitions(): Promise<any[]> {
        const response = await AppHttp.get(`${this.resource}/Definitions`);
        
        if (!response || !response.data)
            return [];
        else
            return response.data.actualsDefinitions;
    }
    
    public async downloadCsv(departmentId: any, startDate: any, endDate: any) {       
        const response = await AppHttp.post(
            `${this.resource}/DownloadActualsCsv`, 
            {
                departmentId,
                startDate,
                endDate
            });
        
        return response;
    }
        
    public async getGroupsForDepartmentShiftId(shiftId: string): Promise<any[]> {
        const response = await AppHttp.get(`${this.resource}/GetGroupsForDepartmentShift?departmentShiftId=${shiftId}`);

        if (!response || !response.data)
            return [];
        else
            return response.data.actualsGroups;
    }
    
    public async getActualsDataForDepartmentShiftId(
        shiftId: string,
        taskTypeId?: string,
        operatorId?: string,
        equipmentId?: string,
        locationId?: string
    ): Promise<any[]> {        
        let url = `${this.resource}/GetForDepartmentShift?departmentShiftId=${shiftId}`;
        
        if (taskTypeId)
            url += `&taskTypeId=${taskTypeId}`;
        if (operatorId)
            url += `&operatorId=${operatorId}`;
        if (equipmentId)
            url += `&equipmentId=${equipmentId}`;
        if (locationId)
            url += `&locationId=${locationId}`;
        
        const response = await AppHttp.get(url);
        
        if (!response || !response.data)
            return [];
        else 
            return response.data.actualsResponses;
    }

    public async update(shiftId: string, commands: UpdateActualsCommand[]) {
        const response = await AppHttp.put(`${this.resource}/Shift/${shiftId}`, commands);
        const data = response ? response.data : null;
        return data;
    }

    public async populateFromShiftPlan(shiftId: string) {
        await AppHttp.post(`${this.resource}/Shift/${shiftId}/Populate`, null);
    }
}

export default new Actuals();