import {
    ActualPhysicalsEntryViewModel,
    BlastPacketTaskInWeekViewModel,
    BlastPacketTasksInWeekLocationViewModel,
    CycleType,
    DelayTypes, DepartmentViewModel,
    Heading,
    LocationPlanningPriorityViewModel,
    LocationStopeInformationViewModel,
    PlannedEquipmentViewModel,
    ShiftBoardRow,
    ShiftPlannedTask,
    SpecialTaskSpan,
    TaskTypeViewModel,
    TaskViewModel,
    WeekBoardRow,
    WeekTaskViewModel, WeekViewModel
} from '@/models/api';
import ClientRowModel from '@/models/client/client-row';
import ClientTaskModel from '@/models/client/client-task';
import { getRowType } from '@/lib/PlannedLocation';
import dayjs, { Dayjs } from 'dayjs';
import { TIME_UNIT_MINUTES } from '../Constants';
import { NoGoType } from '@/models/client/types/no-go-type';
import { NoGoZone } from '@/models/client/no-go-zone';
import NoGoZoneFinder from '@/lib/services/NoGoZoneFinder';
import { isArray } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { ClientBlastPacketActualsEntry } from '@/models/client/client-actuals';
import { ClientRowBlastPacketTargetDisplayInformation } from '@/lib/stores/Production/ShiftWindowActualsStore';
import WeekTask from '@/models/client/week-task';
import { BlastPacket } from '@/lib/stores/Production/BlastPacketsStore';
import { RowType } from '@/models/client/types/row-type';
import { ProductionValidationTaskModel } from '@/models/client/production-validation-task-model';
import { ProductionValidationRow } from '@/models/client/production-validation-row';
import { RemoveLocationNameFromBlastPacketOrRingName } from '@/lib/services/Production';
import { ClientWeekBoardRow } from '@/models/client/client-week-board-row';
import InteractionsClientRowModel from '@/models/client/interactions-client-row';

/// Use this function to convert your Date to a Dayjs object if it has no offset (and is in Minetime)
export const TransformDateTimeToClientDate = (serverDate: Date): Dayjs => {
    return dayjs.utc(serverDate);
}

/// Use this function to convert your Date to a Dayjs object if it contains an offset
export const TransformDateTimeOffsetToClientDate = (serverDate: Date): Dayjs => {
    return dayjs(serverDate).utc();
}

export const transformShiftBoardRowToInteractionsClientRow = (row: ShiftBoardRow, shiftStartTime: Dayjs,
                                                              shiftsPerDay: number, departments: DepartmentViewModel[]): InteractionsClientRowModel => {
    const forwardNoGoGenerator = forwardGenerator(shiftStartTime, shiftsPerDay, row.sosTaskSpans, row.eosTaskSpan);
    const backwardNoGoGenerator = backwardGenerator(shiftStartTime, shiftsPerDay, row.sosTaskSpans, row.eosTaskSpan);

    const ignoreSos = row.sosTaskSpans === null || row.sosTaskSpans === undefined || row.sosTaskSpans.length === 0;
    const ignoreEos = row.eosTaskSpan === null || row.eosTaskSpan === undefined;

    const nextTag = uuidv4();

    const clientRow: InteractionsClientRowModel = {
        id: row.id,
        priority: row.priority,
        location: {
            _type: 'LocationModel',
            id: row.location!.id,
            name: row.location?.name ?? null,
            unavailable: row.shiftLocationDetails!.unavailable,
            cycleType: row.location?.cycleType ?? null,
            reducedLevelName: row.location?.reducedLevel?.name ?? null,
            reducedLevelId: row.location?.reducedLevelId ?? null,
            locationTaskPlanningTag: row.location?.planningUpdateTag ?? null,
            extendedProperties: row.location?.extendedProperties ?? [],
        },
        stopeInfo: row.location?.stopeInfo ?? null,
        rowType: getRowType(row),
        pendingCycles: row.progress!.pending,
        completedCycles: row.progress!.complete,
        targetCycles: row.progress!.target,
        nextShiftSoSSpans: [],
        nextShiftEoSSpans: [],
        next24HoursOfLocationDetails: [],
        sosSpans:
            row.sosTaskSpans !== null && row.sosTaskSpans.length !== 0
                ? row.sosTaskSpans.map((x) =>
                    transformSpecialTaskSpanToClientSpecialSpan(x, shiftStartTime, NoGoType.StartOfShift)
                )
                : null,
        eosSpan:
            row.eosTaskSpan !== null
                ? transformSpecialTaskSpanToClientSpecialSpan(row.eosTaskSpan, shiftStartTime, NoGoType.EndOfShift)
                : null,
        noGoZoneFinder: new NoGoZoneFinder(forwardNoGoGenerator, backwardNoGoGenerator, ignoreSos, ignoreEos),

        eosTask: row.eosTask,
        sosTasks: row.sosTasks,
        soS: row.soS,
        eoS: row.eoS,
        shiftLocationDetails: row.shiftLocationDetails,
        planningPriorities:
            row.location?.planningPriorities?.map((pp: LocationPlanningPriorityViewModel) => ({
                priority: pp.priority,
                priorityType: pp.priorityType,
            })) ?? [],
        weekHasPlannedCycles: row.weekHasPlannedCycles,
        tags: {
            current: row.location?.planningUpdateTag ?? uuidv4(),
            next: nextTag
        },
        tasks: row.tasks.map(t=>transformTaskViewModelToClientTask(t)),
        departmentName: departments.find(d=>d.id===row.location?.departmentId)?.name ?? ''
    };

    return clientRow;

}

export const transformRowsToHeadings = (
    rows: Heading[],
    tasks: ClientTaskModel[],
    shiftStartTime: Dayjs,
    shiftsPerDay: number,
    nextShiftStartTimes: Dayjs[],
): ClientRowModel[] => {
    const clientRows: ClientRowModel[] = [];
    for (const row of rows) {
        const tasksForRow = tasks
            .filter((task) => task.locationId === row.location!.id)
            .sort((a, b) => {
                if (a.startTime.isSame(b.startTime)) {
                    if (a.durationMinutes === 0 && b.durationMinutes !== 0) return -1;
                    else if (a.durationMinutes !== 0 && b.durationMinutes === 0) return 1;
                    else return 0;
                }

                return a.startTime.diff(b.startTime);
            });
        const clientRow = transformRowsToHeading(row, row, tasksForRow, shiftStartTime, shiftsPerDay, nextShiftStartTimes);
        clientRows.push(clientRow);
    }

    return clientRows;
};

export const transformActualsToClient = (viewModels: ActualPhysicalsEntryViewModel[]): ClientBlastPacketActualsEntry[] => {
    return viewModels.map(a=>({
        id: a.id,
        targetId: a.blastPacketRingTargetId!,
        locationId: a.locationId!,
        endTime: TransformDateTimeOffsetToClientDate(a.endTime),
        comment: a.comment,
        quantity: a.quantity,
        startTime: a.startTime != null ? dayjs(a.startTime).utc() : undefined,
        ringName: a.ringName,
        blastPacketName: a.blastPacketName!,
        locationName: a.locationName!,
        targetName: a.targetName!,
    }));
}

export const transformRowsToHeading = (
    oldRow: Heading,
    row: Heading,
    tasks: ClientTaskModel[],
    shiftStartTime: Dayjs,
    shiftsPerDay: number,
    nextShiftStartTimes: Dayjs[]
): ClientRowModel => {
    const forwardNoGoGenerator = forwardGenerator(shiftStartTime, shiftsPerDay, row.sosTaskSpans, row.eosTaskSpan);
    const backwardNoGoGenerator = backwardGenerator(shiftStartTime, shiftsPerDay, row.sosTaskSpans, row.eosTaskSpan);

    const ignoreSos = row.sosTaskSpans === null || row.sosTaskSpans === undefined || row.sosTaskSpans.length === 0;
    const ignoreEos = row.eosTaskSpan === null || row.eosTaskSpan === undefined;

    const nextTag = uuidv4();

    // @ts-ignore
    const clientRow: ClientRowModel = {
        id: oldRow.id,
        priority: oldRow.priority,
        location: {
            _type: 'LocationModel',
            id: oldRow.location!.id,
            name: oldRow.location?.name ?? null,
            unavailable: oldRow.shiftLocationDetails!.unavailable,
            cycleType: oldRow.location?.cycleType ?? null,
            reducedLevelName: oldRow.location?.reducedLevel?.name ?? null,
            reducedLevelId: oldRow.location?.reducedLevelId ?? null,
            locationTaskPlanningTag: oldRow.location?.planningUpdateTag ?? null,
            extendedProperties: oldRow.location?.extendedProperties ?? [],
        },
        stopeInfo: row.location?.stopeInfo ?? null,
        rowType: getRowType(row),
        tasks: [],
        pendingCycles: row.progress!.pending,
        completedCycles: row.progress!.complete,
        targetCycles: row.progress!.target,
        next24HoursOfLocationDetails: row.next24HoursOfLocationDetails,
        nextShiftSoSSpans: nextShiftStartTimes.map((x) => {
            if(row.sosTaskSpans !== null && row.sosTaskSpans.length !== 0) {
                return row.sosTaskSpans.map((sosSpan) => {
                    const span = transformSpecialTaskSpanToClientSpecialSpan(sosSpan, x, NoGoType.StartOfShift);
                    return span;
                });
            } else {
                return null;
            }
        }).filter(x=>x!==null).map(x=>x!),
        nextShiftEoSSpans: nextShiftStartTimes.map((x) => {
            if(row.eosTaskSpan !== null) {
                const span = transformSpecialTaskSpanToClientSpecialSpan(row.eosTaskSpan, x, NoGoType.EndOfShift);
                return span;
            } else {
                return null;
            }
        }).filter(x=>x!==null).map(x=>x!),
        sosSpans:
            row.sosTaskSpans !== null && row.sosTaskSpans.length !== 0
                ? row.sosTaskSpans.map((x) =>
                    transformSpecialTaskSpanToClientSpecialSpan(x, shiftStartTime, NoGoType.StartOfShift)
                )
                : null,
        eosSpan:
            row.eosTaskSpan !== null
                ? transformSpecialTaskSpanToClientSpecialSpan(row.eosTaskSpan, shiftStartTime, NoGoType.EndOfShift)
                : null,
        noGoZoneFinder: new NoGoZoneFinder(forwardNoGoGenerator, backwardNoGoGenerator, ignoreSos, ignoreEos),

        eosTask: row.eosTask,
        sosTasks: row.sosTasks,
        soS: row.soS,
        eoS: row.eoS,
        shiftLocationDetails: row.shiftLocationDetails,
        planningPriorities:
            row.location?.planningPriorities?.map((pp: LocationPlanningPriorityViewModel) => ({
                priority: pp.priority,
                priorityType: pp.priorityType,
            })) ?? [],
        weekHasPlannedCycles: row.weekHasPlannedCycles,
        tags: {
            current: row.location?.planningUpdateTag ?? uuidv4(),
            next: nextTag
        }
    };

    for (const task of tasks) {
        clientRow.tasks.push(task);
    }

    return clientRow;
};

export const transformTimeToNearestTimeUnitFloor = (time: dayjs.Dayjs): dayjs.Dayjs => {
    const currentMinutes = time.minute();
    const targetMinutes = transformToNearestTimeUnitFloor(currentMinutes);
    return time.minute(targetMinutes);
}

export const transformTimeToNearestTimeUnitCeiling = (time: dayjs.Dayjs): dayjs.Dayjs => {
    const currentMinutes = time.minute();
    const targetMinutes = transformToNearestTimeUnitCeiling(currentMinutes);
    return time.minute(targetMinutes);
}

export const transformToNearestTimeUnitFloor = (durationInMinutes: number): number => {
    return Math.floor(durationInMinutes / TIME_UNIT_MINUTES)*TIME_UNIT_MINUTES;
}

export const transformToNearestTimeUnitCeiling = (durationInMinutes: number): number => {
    return Math.ceil(durationInMinutes / TIME_UNIT_MINUTES) * TIME_UNIT_MINUTES;
}

const timeToUnitTranslation = (unitZeroTime: Dayjs, startTime: Dayjs, unitMinusOne: boolean): number => {
    const differenceInMinutes = startTime.diff(unitZeroTime, 'minutes');
    const differenceInUnits = differenceInMinutes / TIME_UNIT_MINUTES;

    return unitMinusOne ? differenceInUnits - 1 : differenceInUnits;
};

export const extractBlastPacketDisplayInformation = (ringName: string | null | undefined, blastPacketName: string | null | undefined, locationName: string | null | undefined): string | null => {
    const unalteredName = ringName ?? blastPacketName ?? null;

    if(unalteredName === null || locationName == null) return unalteredName;

    return RemoveLocationNameFromBlastPacketOrRingName(unalteredName, locationName);
}


export const transformWeekRowToEvaluatableRow = (weekRow: { id: string, location: { name: string | null, cycleType: CycleType | null, stopeInfo: LocationStopeInformationViewModel | null } | null }, location: BlastPacketTasksInWeekLocationViewModel, taskTypes: TaskTypeViewModel[]): ProductionValidationRow => {
    const locationInformation = { id: location.locationId!, name: weekRow.location?.name ?? '' };

    return {
        id: weekRow.id,
        rowType: weekRow.location?.cycleType === CycleType.Rate ? RowType.RATE : RowType.GENERIC,
        location: locationInformation,
        stopeInfo: weekRow.location?.stopeInfo ?? null,
        tasks: location.tasks.map(t=>transformBlastPacketTaskToEvaluatableTask(t, taskTypes, locationInformation))
    };
}

export const transformBlastPacketTaskToEvaluatableTask = (task: BlastPacketTaskInWeekViewModel, taskTypes: TaskTypeViewModel[], locationInformation: { id: string; name: string | null; }): ProductionValidationTaskModel => {
    const taskType = taskTypes.find(x=>x.id===task.taskTypeId);

    if(taskType === undefined) throw new Error(`Could not find task type ${task.taskTypeId}`);

    return {
        id: task.id,
        locationId: locationInformation.id,
        locationName: locationInformation.name,
        blastPacketRingTargetId: task.blastPacketRingTargetId,
        taskType: taskType,
        taskTypeId: task.taskTypeId,
        startTime: TransformDateTimeOffsetToClientDate(task.startAt),
        endTime: TransformDateTimeOffsetToClientDate(task.endAt),
        durationMinutes: task.durationMinutes,
        quantity: task.quantity,
        plannedCycleId: task.plannedCycleId,
        ratePerHour: task.ratePerHour,
        warnings: []
    };
}

export const transformWeekRowToClient = (row: WeekBoardRow, tasks: WeekTask[]): ClientWeekBoardRow => {
    const rowType = getRowType(row);

    return {
        id: row.id,
        availableCycles: row.availableCycles,
        cyclesPlanned: row.cyclesPlanned,
        cyclesRequired: row.cyclesRequired,
        metresToAdvance: row.metresToAdvance,
        numCompletions: row.numCompletions,
        priority: row.priority,
        rowType: rowType,
        tasks: tasks,
        tonnesToFire: row.tonnesToFire,
        location: {
            id: row.location?.id ?? '',
            name: row.location?.name ?? '',
            miningMethod: row.location?.miningMethod,
            cycleType: row.location?.cycleType ?? null,
            planningUpdateTag: row.location?.planningUpdateTag,
            parentLocationId: row.location?.parentLocationId,
            stopeInfo: row.location?.stopeInfo ?? null,
            reducedLevelName: row.location?.reducedLevel?.name ?? '',
            extendedProperties: row.location?.extendedProperties ?? [],
            commentary: row.location?.commentary ?? null
        }
    }
}

export const transformTaskViewModelToClientTask = (task: TaskViewModel): ClientTaskModel => {
    const clientTask: ClientTaskModel = {
        ...task,
        comments: task.allComments,
        plannedEquipment: [],
        locationId: task.locationId!,
        offset: 0,
        taskType: task.taskType!,
        taskTypeId: task.taskType!.id,
        taskCategoryId: null,
        error: task.error,
        warnings: [],
        errors: [],
        lockedInPast: false,
        primaryEquipment: null,
        isDeleted: false,
        isDelay: task.taskType?.isDelay,
        isFloatable: (task.taskType?.delayType === DelayTypes.Float ||
            // @ts-ignore
            task.taskType?.delayType === 1 ||
            // @ts-ignore
            task.taskType?.delayType === 'Float'),
        startTime: dayjs(task.startAt).utc(),
        endTime: dayjs(task.endAt).utc(),
        equipmentAssignedOn: dayjs().utc(),
        blastPacketDisplayInformation: null as string | null
    };

    return clientTask;
}

export const transformTaskViewModelToWeekTask = (task: WeekTaskViewModel, week: WeekViewModel, blastPacketTargets: BlastPacket[]): WeekTask => {
    const startTime = dayjs(task.startAt).utc();
    const endTime = dayjs(task.endAt).utc();
    const durationInMinutes = endTime.diff(startTime, 'minute');

    const transformedTask: WeekTask = {
        ...task,
        _type: "ShiftPlannedTask",
        taskType: task.taskType!,
        taskTypeId: task?.taskType?.id ?? null,
        taskCategoryId: task.taskCategory?.id ?? null,
        dropTarget: false,
        lockedInPast: false,
        comments: [],
        plannedEquipment: [],
        primaryEquipment: null,
        isActive: false,
        isSuggestion: false,
        isLongRunningTask: task.isLongRunningTask,
        offset: 0,
        errors: [],
        warnings: [],
        startTime: startTime,
        endTime: endTime,
        blastPacketId: '',
        blastPacketName: '',
        fireTaskTimes: task.fireTasks.map(ft=>{
            const fireTaskEndTime = dayjs(ft.endAt).utc();
            const fireTaskStartTime = dayjs(ft.startAt).utc();
            const percentageWidth = Math.max(60, fireTaskEndTime.diff(fireTaskStartTime, 'minute')) / durationInMinutes * 100;
            return {
                startPercentage: fireTaskEndTime.diff(startTime, 'minute') / durationInMinutes * 100 - percentageWidth,
                percentageWidth
            }
        }),
        canResize: task.canResize,
        canMove: !startTime.isBefore(week.weekStartDate),
        canUpdateQuantity: task.canUpdateQuantity,
    };

    if(task.blastPacketRingTargetId != null){
        const associatedTarget = blastPacketTargets.find(bpt=>bpt.blastPacketRingTargetId===task.blastPacketRingTargetId);

        if(associatedTarget != null) {
            transformedTask.blastPacketId = associatedTarget.blastPacketId ?? '';
            transformedTask.blastPacketName = RemoveLocationNameFromBlastPacketOrRingName(associatedTarget.blastPacketName, task.locationName) ?? '';

            if(associatedTarget.blastPacketRingId != null) {
                transformedTask.blastPacketDisplayInformation = RemoveLocationNameFromBlastPacketOrRingName(associatedTarget.ringName, task.locationName) ?? '';
            } else {
                transformedTask.blastPacketDisplayInformation = transformedTask.blastPacketName;
            }
        }
    }

    return transformedTask;
}

export const transformTaskToClientTask = (task: ShiftPlannedTask, taskTypes: TaskTypeViewModel[], blastPacketAndRingNames?: ClientRowBlastPacketTargetDisplayInformation[]): ClientTaskModel => {
    const taskType = taskTypes.find((x) => x.id === task.taskTypeId);

    if (taskType === undefined) throw new Error(`Could not find task type ${task.taskTypeId}`);

    let primaryEquipment: any = null;

    if (task.plannedEquipment) {
        if (isArray(task.plannedEquipment)) {
            primaryEquipment = task.plannedEquipment.find((equipment) => equipment.isPrimary);
        } else {
            primaryEquipment = task.plannedEquipment;
        }
    }

    const clientTask = {
        ...task,
        headingId: task.headingId!,
        locationId: task.locationId!,
        offset: 0,
        taskType: taskType,
        error: task.error,
        errors: [],
        warnings: [],
        dropTarget: false,
        lockedInPast: false,
        isDelay: taskType.isDelay,
        conflict: false,
        isFloatable: (taskType.delayType === DelayTypes.Float ||
            // @ts-ignore
            taskType.delayType === 1 ||
            // @ts-ignore
            taskType.delayType === 'Float'),
        primaryEquipment: primaryEquipment ?? null,
        isDeleted: false,
        startTime: dayjs(task.startAt).utc(),
        endTime: dayjs(task.endAt).utc(),
        equipmentAssignedOn: dayjs().utc(),
        blastPacketDisplayInformation: null as string | null
    };

    if(task.blastPacketRingTargetId != null && blastPacketAndRingNames != null) {
        const blastPacketAndRingName = blastPacketAndRingNames.find(t=>t.targetId === task.blastPacketRingTargetId);

        clientTask.blastPacketDisplayInformation = extractBlastPacketDisplayInformation(blastPacketAndRingName?.ringName, blastPacketAndRingName?.blastPacketName, task.locationName);
    }

    return clientTask;
};

export const transformRowsToOfflineRows = (rows: ClientRowModel[]): any[] => {
    return rows.map((r) => ({
        ...r,
        tasks: r.tasks.map((t) => {
            const task = {
                ...t,
                startAt: t.startTime.toDate(),
                endAt: t.endTime.toDate(),
            };

            return task;
        }),
    }));
};

export const transformSpecialTaskSpanToClientSpecialSpan = (
    span: SpecialTaskSpan,
    shiftStartTime: Dayjs,
    noGoType: NoGoType
) => {
    const transformed = {
        startTime: shiftStartTime.add(span.start * TIME_UNIT_MINUTES, 'minutes'),
        endTime: shiftStartTime.add((span.end + 1) * TIME_UNIT_MINUTES, 'minutes'),
        taskType: span.task!,
        noGoType: noGoType,
        backgroundColour: span.backgroundColour!,
    };

    return transformed;
};

function* backwardGenerator(
    firstShiftStartTime: Dayjs,
    shiftsPerDay: number,
    sosSpans: SpecialTaskSpan[],
    eosSpan: SpecialTaskSpan | null
): IterableIterator<NoGoZone[]> {
    const shiftDuration = (24 * 60) / shiftsPerDay;
    let startShiftTime = firstShiftStartTime.add(-1 * shiftDuration, 'minutes');

    const ignoreSos = sosSpans === null || sosSpans === undefined || sosSpans.length === 0;
    const ignoreEos = eosSpan === null || eosSpan === undefined;

    let count = 0;
    while (count < 1000) {
        const noGos: NoGoZone[] = [];

        if (ignoreSos === false) {
            const sosNoGo: NoGoZone = {
                startTime: startShiftTime.add(sosSpans[0].start * TIME_UNIT_MINUTES, 'minutes'),
                endTime: startShiftTime.add((sosSpans[sosSpans.length - 1].end + 1) * TIME_UNIT_MINUTES, 'minutes'),
                noGoType: NoGoType.StartOfShift,
            };

            noGos.push(sosNoGo);
        }

        if (ignoreEos === false) {
            const eosNoGo = {
                startTime: startShiftTime.add(eosSpan!.start * TIME_UNIT_MINUTES, 'minutes'),
                endTime: startShiftTime.add((eosSpan!.end + 1) * TIME_UNIT_MINUTES, 'minutes'),
                noGoType: NoGoType.EndOfShift,
            };

            noGos.push(eosNoGo);
        }

        startShiftTime = startShiftTime.add(-1 * shiftDuration, 'minutes');
        count++;

        yield noGos;
    }

    throw new Error('NoGoZoneGeneratorFunction: Too many iterations backward');
}

function* forwardGenerator(
    firstShiftStartTime: Dayjs,
    shiftsPerDay: number,
    sosSpans: SpecialTaskSpan[],
    eosSpan: SpecialTaskSpan | null
): IterableIterator<NoGoZone[]> {
    const shiftDuration = (24 * 60) / shiftsPerDay;
    let startShiftTime = firstShiftStartTime.add(-1 * shiftDuration, 'minutes');

    const ignoreSos = sosSpans === null || sosSpans === undefined || sosSpans.length === 0;
    const ignoreEos = eosSpan === null || eosSpan === undefined;
    let count = 0;
    while (count < 1000) {
        const noGos: NoGoZone[] = [];

        if (ignoreSos === false) {
            const sosNoGo: NoGoZone = {
                startTime: startShiftTime.add(sosSpans[0].start * TIME_UNIT_MINUTES, 'minutes'),
                endTime: startShiftTime.add((sosSpans[sosSpans.length - 1].end + 1) * TIME_UNIT_MINUTES, 'minutes'),
                noGoType: NoGoType.StartOfShift,
            };

            noGos.push(sosNoGo);
        }

        if (ignoreEos === false) {
            const eosNoGo = {
                startTime: startShiftTime.add(eosSpan!.start * TIME_UNIT_MINUTES, 'minutes'),
                endTime: startShiftTime.add((eosSpan!.end + 1) * TIME_UNIT_MINUTES, 'minutes'),
                noGoType: NoGoType.EndOfShift,
            };

            noGos.push(eosNoGo);
        }

        startShiftTime = startShiftTime.add(shiftDuration, 'minutes');
        count++;

        yield noGos;
    }

    throw new Error('NoGoZoneGeneratorFunction: Too many iterations forward');
}

export const transformPlannedEquipment = (
    plannedEquipment: PlannedEquipmentViewModel,
    taskTypes: TaskTypeViewModel[]
) => {
    plannedEquipment.plannedTasks.forEach((task) => {
        const taskType = taskTypes.find((x) => x.id == task.taskTypeId);
        task.taskType = taskType ?? null;
    });
};