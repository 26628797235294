<template>
  <div class="pa-5">
    <v-card v-if="data">
      <v-card-title>
        <h1 class="title">Actuals Definitions</h1>
        <v-btn small color="secondary" class="ml-3" @click="create()">
          <v-icon left>mdi-plus</v-icon>Add
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table disable-pagination hide-default-footer :headers="headers" :items="data">
        <template v-slot:item="{ item }">
          <tr :class="{ dirty: item.dirty }">
            <td>{{ item.groupDefinitionName }}</td>
            <td>{{ item.groupDefinitionType }}</td>
            <td>
              <ul class="mt-4 mb-4" v-if="item.associatedGroups">
                <li v-for="(group, index) in item.associatedGroups" :key="index">
                  {{ group.groupName }}
                </li>
              </ul>
            </td>
            <td>
              <div class="no-wrap">
                <v-btn text icon @click="edit(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <standard-dialog :render="!!selected" :value='showModal' :max-width="700" persistent>
      <template v-slot:title>
        <span v-if="selected.id">Edit</span>
        <span v-else>Create</span>
        <span class="ml-1">{{ 'Actuals Definition' | term }}</span>
      </template>
      <template v-slot:content>
        
        <v-form ref="form" :lazy-validation="true">
          <v-container fluid>
            <v-row v-if="selected.id">
              <v-col cols="12">
                <v-text-field
                    label="Id"
                    v-model="selected.id"
                    disabled
                ></v-text-field>
              </v-col>
            </v-row>  
            <v-row>
              <v-col cols="12">
                <v-text-field
                    autocomplete="off"
                    label="Name"
                    :rules="[$rules.required(), $rules.maxLength(50)]"
                    v-model="selected.name"
                    counter="50"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                    v-model="selected.type"
                    :items="Object.keys(ActualsGroupDefintionsDataTypes).filter((key) => isNaN(Number(key)))"
                    label="Field Type"
                    :rules="[$rules.required()]"
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="selected.type === 'Formula'">
              <v-col cols="12">
                <v-text-field
                    autocomplete="off"
                    label="Formula"
                    hint="Eg. [Drill Length] * [Count] + 15"
                    :rules="[$rules.required()]"
                    v-model="selected.formula"
                    persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="selected.type === 'Formula' || selected.type === 'Decimal' || selected.type === 'Integer'">
              <v-col cols="3">
                <v-checkbox
                    label="Summable"
                    v-model="selected.summable"
                    hide-details></v-checkbox>
              </v-col>
              <v-col cols="3" v-if="selected.type === 'Formula' || selected.type === 'Decimal'">
                <v-select
                    label="Decimal Places"
                    :items="decimalOptions"
                    v-model="selected.decimalPlaces"
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="mt-8">
              <v-select
                  v-model="selected.groupIds"
                  :items="definitionGroups"
                  item-text="groupName"
                  item-value="groupId"
                  chips
                  label="Assigned Definition Groups"
                  multiple
                  outlined
              ></v-select>
            </v-row>

          </v-container>
        </v-form>
        
      </template>
      <template v-slot:actions>
        <v-btn color='red' text v-if='selected && selected.id' @click='del()'>Delete</v-btn>
        <v-spacer></v-spacer>
        <v-btn color='gray' text @click='showModal = false'>Cancel</v-btn>
        <v-btn color='primary' text @click='save()'>Save</v-btn>
      </template>
      <template v-slot:offline-actions>
        <v-spacer></v-spacer>
        <v-btn color='gray' text @click='showModal = false'>Cancel</v-btn>
        <offline-btn text></offline-btn>
      </template>
    </standard-dialog>
    <confirm ref="confirm"></confirm>
  </div>
</template>
<script>
import { EventBus, Events } from '@/lib/EventBus';
import EquipmentRoles from '@/lib/data/EquipmentRoles';
import DownloadHelper from '@/lib/DownloadHelper';
import Actuals from '@/lib/data/Actuals';
import { mapState } from 'pinia';
import {ActualsGroupDefintionsDataTypes, UpsertActualsDefinitionCommand} from "@/models/api";

export default {
  data() {
    return {
      data: null,
      showModal: false,
      selected: null,
      importDialog: false,
      file: null,
      definitionGroups: null,
      headers: [
        { text: 'Definition Name', value: 'name', width: '30%' },
        { text: 'Type', value: 'type', width: '25%'},
        { text: 'Assigned Groups', value: 'name', width: '43%' },
        { text: '', value: 'id', width: '2%', align: 'right' }
      ],
      decimalOptions: [1, 2]
    };
  },
  async created() {
    await this.loadData();
  },
  destroyed() {},
  methods: {
    async loadData() {
      var data = (await Actuals.getDefinitions()).sort((a, b) => a.groupDefinitionName.localeCompare(b.groupDefinitionName));
      this.definitionGroups = (await Actuals.getGroups()).sort((a, b) => a.groupName.localeCompare(b.groupName));
      
      for (let d of data) {
        d.dirty = false;
      }

      this.data = data;
    },
    create() {
      var displayOrder = this.data.length ? this.data[this.data.length - 1].displayOrder + 1 : 1;
      this.edit({
        decimalPlaces: 1
      });
    },
    edit(item) {
      this.selected = {
        id: item.groupDefinitionId,
        name: item.groupDefinitionName,
        type: item.groupDefinitionType,
        formula: item.formula,
        summable: item.summable,
        groupIds: item.associatedGroups ? item.associatedGroups.map(x => x.groupId) : [],
        decimalPlaces: item.decimalPlaces
      };
      this.showModal = true;
      setTimeout(() => this.$refs.form.resetValidation(), 1);
    },
    up(item) {
      if (item.displayOrder > 1) {
        item.displayOrder--;
        item.dirty = true;
      }
    },
    down(item) {
      item.displayOrder++;
      item.dirty = true;
    },
    async del() {
      if (await this.$refs.confirm.openAsDeleteResource(this.$termSync('Actuals Definition'),  { })) {
        await Actuals.deleteDefinition(this.selected.id);
        EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Actuals Definition')} Deleted`);
        this.showModal = false;
        await this.loadData();
      }
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.selected.type !== 'Formula' && this.selected.type !== 'Decimal' && this.selected.type !== 'Integer') 
        this.selected.summable = null
        
      if (this.selected.type !== 'Formula' && this.selected.type !== 'Decimal')
        this.selected.decimalPlaces = null

      await Actuals.upsertDefinition(this.selected);
      
      EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Actuals Definition')} Saved`);
      this.showModal = false;
      await this.loadData();
    },
    async exportData() {
      const result = await EquipmentRoles.export();
      var csv = result.csvFile;
      var blob = DownloadHelper.makeBlobFromFileString(csv);
      DownloadHelper.download('EquipmentRoles.csv', blob);
    },
    async importData() {
      try {
        this.$wait.start('saving');
        this.uploadResult = null;
        await EquipmentRoles.import(this.file);
        this.importDialog = false;
        await this.loadData();
      } finally {
        this.$wait.end('saving');
      }
    },
    async getFile(e) {
      this.file = e.target.files[0];
    }
  },
  computed: {
    ActualsGroupDefintionsDataTypes() {
      return ActualsGroupDefintionsDataTypes
    },
    dirty() {
      for (let x of this.data) {
        if (x.dirty) {
          return true;
        }
      }
      return false;
    }
  }
};
</script>
<style lang="scss" scoped></style>
