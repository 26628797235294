<template>
  <shift-loader
      name="shift"
      :isLoading="!dataLoaded"
      @loaded="loadData"
  >
  <div style="width: 100%; align-self: baseline;" class="pr-15 pl-15">

      <h2 class="mt-7">
        {{ heading }}
        <v-chip
            v-if="!editing"
            class="ma-2 status-chips"
            text-color="white"
            color="secondary"
        >
          Read Only
        </v-chip>

        <v-chip
            v-if="editing"
            class="ma-2 status-chips"
            text-color="white"
            color="green"
        >
          Editing
        </v-chip>
      </h2>

    <div class="header-container mt-1 mb-2">
      <v-card outlined class="w-auto">
        <div class="w-auto d-flex flex-row align-center pa-2">
          <v-select
              dense
              v-model="filterEquipment"
              :items="filterOptions.equipments"
              label="Equipment"
              outlined
              hide-details
              item-text="value"
              class="filter-options mr-2"
              return-object
          >
          </v-select>
          <v-select
              dense
              v-model="filterLocation"
              :items="filterOptions.locations"
              label="Location"
              outlined
              hide-details
              item-text="value"
              class="filter-options mr-2"
              return-object
          >
          </v-select>
          <v-select
              dense
              v-model="filterTaskType"
              :items="filterOptions.taskTypes"
              label="Task Type"
              outlined
              hide-details
              item-text="value"
              class="filter-options mr-2"
              return-object
          >
          </v-select>
          <v-select
              dense
              v-model="filterOperator"
              :items="filterOptions.operators"
              label="Operator"
              outlined
              hide-details
              item-text="value"
              class="filter-options mr-2"
              return-object
          >
          </v-select>
          <v-btn class="filter-button mr-2"
              @click="loadData(true)"
              color="primary">
            <span v-if="!filterRefresh">Filter</span>
            <v-progress-circular v-else color="white" indeterminate :size="20" />
          </v-btn>
          <v-btn
              class="clear-filter "
              @click="clearFilter()"
              color="primary">
            <v-icon class="pa-0 ma-0">mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card>
      
      <div>
        <v-chip
            v-if="editing && pageStatus"
            class="ma-2 status-chips"
            text-color="white"
            :color="pageStatus === 'Saved' ? 'green' : 'orange'"
        >
          {{ pageStatus }}
        </v-chip>

        <v-btn
            v-if="editing"
            :disabled="saving"
            @click="showExportToCsvModal = true"
            color="primary"
            class="ml-2 ">
          Export to CSV
        </v-btn>

        <v-btn
            v-if="editing"
            :disabled="saving"
            @click="addRow()"
            color="primary"
            class="ml-2 ">
          Add New Row
        </v-btn>
        
        <v-btn
            v-if="!editing"
            @click="switchToEdit()"
            color="primary"
            class="ml-2 ">
          Enter Edit Mode
        </v-btn>

        <v-btn
            v-if="editing"
            :disabled="saving"
            @click="finishEditing()"
            color="primary"
            class="ml-2 ">
          Save And Exit
        </v-btn>        
      </div>
    </div>
    
    <v-card v-if="activeFilteredLocation" outlined elevation="1" class="mt-4 mb-2">
      <v-card-title>
        High Level Actuals for&nbsp;<i>{{ activeFilteredLocation.value }}</i>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="2" class="d-flex align-center">
            <b>Meters Advanced:</b>
          </v-col>
          <v-col cols="2">
            <v-text-field
                label="Planned meters"
                class="small-input"
                hide-details
                outlined
                dense
                readonly
                persistent-placeholder
                v-model="metresAdvancedPlanned"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
                label="Supervisor Meters Advanced"
                class="small-input"
                hide-details
                outlined
                dense
                readonly
                persistent-placeholder
                v-model="metresAdvancedSupervisor"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
                label="Actual Meters Advanced"
                class="small-input"
                hide-details
                outlined
                dense
                persistent-placeholder
                :readonly="uiLocked"
                v-model="metresAdvancedActual"
                v-on="{ keypress: (e) => validateInput(e, 'Decimal') }"
                @input="handleMetresAdvancedChanged()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    
    <div v-if="dataLoaded && formData.actualsRows.length === 0" style="background-color: #eee; text-align: center;" class="pa-5 mt-5">
      No actuals data found matching this shift and filter criteria.
      
      <div class="mt-5" v-if="editing && filterOptions.taskTypes.length === 1">
        <v-btn
            @click="populateShiftData()"
            color="primary"
            style="text-align: center">
          Populate Data For Shift
        </v-btn>
      </div>
    </div>

    <div v-if="dataLoaded && formData.actualsRows.length > 0" class="table_wrapper">
    
    <v-data-table
        :items="formData.actualsRows"
        :hide-default-footer="true"
        class="custom-table mt-3"
        hide-default-header
        :disable-pagination="true"
        :key="tableKey"
    >
      <!-- Dynamically generating the headers -->
      <template v-slot:header>
        <thead>
        <tr class="header-s" :class="{ 'edit-mode-colors': editing, 'read-mode-colors': !editing }">
          <th rowspan="2" :class="{ 'glued-edit': editing, 'glued-read': !editing }" class="indicator-column"></th>
          <th rowspan="2" :class="{ 'glued-edit': editing, 'glued-read': !editing }" class="extra-wide">
            <div class="sortable-field" @click="changeSorting('equipment')">
              <v-icon :color="editing ? 'white' : 'black'" v-if="activeFilteredEquipment">mdi-filter</v-icon>
              Equipment
              <v-icon :color="editing ? 'white' : 'black'" v-if="sortColumn === 'equipment'">mdi-arrow-{{ sortDirection }}</v-icon>
            </div>
          </th>
          <th rowspan="2" :class="{ 'glued-edit': editing, 'glued-read': !editing }" class="extra-wide">
            <div class="sortable-field" @click="changeSorting('location')">
              <v-icon :color="editing ? 'white' : 'black'" v-if="activeFilteredLocation">mdi-filter</v-icon>
            Location
            <v-icon :color="editing ? 'white' : 'black'" v-if="sortColumn === 'location'">mdi-arrow-{{ sortDirection }}</v-icon>
            </div>
          </th>
          <th rowspan="2" :class="{ 'glued-edit': editing, 'glued-read': !editing }" class="extra-wide">
            <div class="sortable-field" @click="changeSorting('taskType')">
              <v-icon :color="editing ? 'white' : 'black'" v-if="activeFilteredTaskType">mdi-filter</v-icon>
            Task (Type)
            <v-icon :color="editing ? 'white' : 'black'" v-if="sortColumn === 'taskType'">mdi-arrow-{{ sortDirection }}</v-icon>
            </div>
          </th>
          <th rowspan="2" :class="{ 'glued-edit': editing, 'glued-read': !editing }" class="extra-wide">
            <div class="sortable-field" @click="changeSorting('operator')">
              <v-icon :color="editing ? 'white' : 'black'" v-if="activeFilteredOperator">mdi-filter</v-icon>
            Operator
            <v-icon :color="editing ? 'white' : 'black'" v-if="sortColumn === 'operator'">mdi-arrow-{{ sortDirection }}</v-icon>
            </div>
          </th>
          <th rowspan="2" class="glued extra-wide">Comments</th>
          <th rowspan="2" class="glued col-time">Start Time</th>
          <th rowspan="2" class="glued col-time">End Time</th>
          <th class="group-name" v-for="headerGroup in actualsGroups" :key="headerGroup.groupId" :colspan="headerGroup.actualGroupDefinitions.length">
            {{ headerGroup.groupName }}
          </th>
          <th v-if="editing" rowspan="2" class="actions-column"></th>
        </tr>
        <tr class="header-s" :class="{ 'edit-mode-colors': editing, 'read-mode-colors': !editing }">          
          <template v-for="headerGroup in actualsGroups">
            <th v-for="headerGroupItem in headerGroup.actualGroupDefinitions" :class="`col-${headerGroupItem.groupDefinitionType.toLowerCase()}`" :key="`${headerGroup.groupId}-${headerGroupItem.groupDefinitionId}`">
              {{ headerGroupItem.groupDefinitionName }}
              <div v-if="headerGroupItem.groupDefinitionType === 'Formula' && headerGroupItem.formula">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                        v-bind="attrs"
                        v-on="on" class="formula-indicator"
                    >Formula</span>
                  </template>
                  <span>
                    {{ headerGroupItem.formulaDescription }}
                  </span>
                </v-tooltip>
              </div>
            </th>
          </template>
        </tr>
        </thead>
      </template>

      <!-- Body of the table -->
      <template v-slot:body="{ items }">
        <tbody class="tbody">
          <template  v-for="item in items">
            <tr :key="`${item.equipmentId}${item.locationId}${item.taskTypeId}${item.operatorId}`">
              <td>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="warning" v-if="!item.taskTypeId && (!item.taskType || !item.taskType.id)"           
                            v-bind="attrs"
                            v-on="on">mdi-alert-circle</v-icon>
                  </template>
                  <span>Row will not save until Task Type is selected.</span>
                </v-tooltip>

              </td>
              <td>
                <div v-if="!item.isCustom">
                  {{ item.equipment.name }}
                </div>
                <v-combobox
                    v-else
                    v-model="item.equipment"
                    dense
                    outlined
                    :items="equipmentList"
                    hide-details
                    class="small-input"
                    item-text="name"
                    item-value="id"
                    :return-object="true"
                    :readonly="!editing || !!filterEquipment.key"
                    @input="handleInputChange(item, null, null, 'equipment')"
                ></v-combobox>
              </td>
              <td>
                <div v-if="!item.isCustom">
                  {{ item.location.name }}
                </div>
                <v-combobox
                    v-else
                    v-model="item.location"
                    dense
                    outlined
                    :items="locationList"
                    hide-details
                    class="small-input"
                    item-text="name"
                    item-value="id"
                    :return-object="true"
                    :readonly="!editing || !!filterLocation.key"
                    @input="handleInputChange(item, null, null, 'location')"
                ></v-combobox>
              </td>
              <td>
                <div v-if="!item.isCustom">
                  {{ item.taskType.name }}
                </div>
                <v-combobox
                    v-else
                    v-model="item.taskType"
                    dense
                    outlined
                    :items="taskTypeList"
                    hide-details
                    class="small-input"
                    item-text="name"
                    item-value="id"
                    :return-object="true"
                    :readonly="!editing || !!filterTaskType.key"
                    @input="handleInputChange(item, null, null, 'taskType')"
                ></v-combobox>
              </td>
              <td>
                <v-combobox
                    v-model="item.operator"
                    dense
                    outlined
                    :items="staffMembers"
                    hide-details
                    class="small-input"
                    item-text="name"
                    item-value="id"
                    :return-object="true"
                    :readonly="!editing || !!filterOperator.key"
                    @input="handleInputChange(item, null, null, 'operator')"
                ></v-combobox>
              </td>
              <td>
                <v-textarea
                    class="small-input"
                    v-model="item.comment"
                    outlined
                    hide-details
                    single-line
                    dense
                    rows="1"
                    placeholder="Enter comments here..."
                    :readonly="uiLocked"
                    @input="handleInputChange(item)"
                ></v-textarea>
              </td>
              
              <td>
                <v-text-field
                    class="small-input"
                    v-model="item.startTime"
                    hide-details
                    outlined
                    dense
                    :readonly="uiLocked"
                    type="time"
                    @input="handleInputChange(item)"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    class="small-input"
                    v-model="item.endTime"
                    hide-details
                    outlined
                    dense
                    :readonly="uiLocked"
                    type="time"
                    @input="handleInputChange(item)"
                ></v-text-field>
              </td>
              
              
              <template v-for="headerGroup in actualsGroups">
                <td v-for="headerGroupItem in headerGroup.actualGroupDefinitions" :key="`${headerGroup.groupId}-${headerGroupItem.groupDefinitionId}`">                  
                  <v-text-field
                      v-if="headerGroupItem.groupDefinitionType !== 'Boolean'"
                      class="small-input"
                      v-model="item.actualsPairs[headerGroup.groupId + '_' + headerGroupItem.groupDefinitionId]"
                      hide-details
                      outlined
                      dense
                      :disabled="!item.taskType || !headerGroup.taskTypes.some(i => i.taskTypeId === item.taskType.id)"
                      :readonly="uiLocked || headerGroupItem.groupDefinitionType === 'Formula'"
                      :type="getTextFieldType(headerGroupItem.groupDefinitionType)"
                      v-on="{ keypress: (e) => validateInput(e, headerGroupItem.groupDefinitionType) }"
                      @input="handleInputChange(item, headerGroupItem, headerGroup)"
                  ></v-text-field>
                  <v-checkbox
                      v-if="headerGroupItem.groupDefinitionType === 'Boolean'"
                      v-model="item.actualsPairs[headerGroup.groupId + '_' + headerGroupItem.groupDefinitionId]"
                      class="small-input" 
                      hide-details
                      :disabled="!item.taskType || !headerGroup.taskTypes.some(i => i.taskTypeId === item.taskType.id)"
                      :readonly="uiLocked"
                      @change="handleInputChange(item, headerGroupItem, headerGroup)"
                  ></v-checkbox>                  
                </td>
              </template>
              
              <td v-if="editing">
                <v-btn icon color="red" class="mb-2" onclick="alert('Not implemented')"><v-icon>mdi-delete</v-icon></v-btn>
              </td>
            </tr>
          </template>
          <tr v-if="actualsGroups && actualsGroups.length > 0" class="totals-row">
            <td></td>
            <td>
              <div style="height: 40px; font-weight: bold; align-content: center">Totals</div>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <template v-for="headerGroup in actualsGroups">
              <td v-for="headerGroupItem in headerGroup.actualGroupDefinitions" :key="`${headerGroup.groupId}-${headerGroupItem.groupDefinitionId}`">
                <v-text-field
                    :disabled="!headerGroupItem.summable"
                    v-model="totals[`${headerGroup.groupId}_${headerGroupItem.groupDefinitionId}`]"
                    class="small-input"
                    hide-details
                    outlined
                    dense
                    readonly
                >
                </v-text-field>
              </td>
            </template>
            <td></td>
          </tr>
        
        </tbody>
      </template>
    </v-data-table>
      
    </div>
  </div>

    <standard-dialog :render="true" :value='showExportToCsvModal' :max-width="500" persistent>
      <template v-slot:title>
        Export to CSV
      </template>
      <template v-slot:content>

        <v-form ref="form" :lazy-validation="true">
        <v-radio-group
            v-model="csvExportOption"
            :rules="[$rules.required()]"
        >
          <v-radio label="Export data as currently displayed" value="currentDisplay" class="mb-5"></v-radio>
          <v-radio label="Export data by date range" value="dateRange"></v-radio>
        </v-radio-group>
        
        <v-row v-if="csvExportOption === 'dateRange'" class="ml-8">
          <v-text-field
              v-model="csvExportDateRangeStart"
              label="Start Date"
              type="date"
              :rules="[$rules.required()]"
              class="mr-5"
          ></v-text-field>
          <v-text-field
              v-model="csvExportDateRangeEnd"
              label="End Date"
              type="date"
              :rules="[$rules.required()]"
              class="mr-10"
          ></v-text-field>
        </v-row>
        </v-form>
        
        <div class="ml-8" style="width: 200px;">

        </div>
        
      </template>
      <template v-slot:actions>
        <v-spacer />
        <v-btn color='gray' text @click='showExportToCsvModal = false'>Cancel</v-btn>
        <v-btn color='primary' text @click='downloadCSV()'>Download</v-btn>
      </template>
    </standard-dialog>
  </shift-loader>
</template>

<script>
import Equipment from '@/lib/data/Equipment';
import TaskTypes from "@/lib/data/TaskTypes";
import Actuals from '@/lib/data/Actuals';
import Shift from '@/lib/data/Shift';
import { useSystemStore } from "@/lib/stores/SystemStore";
import _ from 'lodash';
import ShiftLoader from "@/components/ShiftBoard/ShiftLoader.vue";
import {mapState} from "pinia";
import { useShiftDetails } from '@/lib/stores/Shift';
import {UpdateActualsCommand} from "@/models/api/Commands/update-actuals-command";
import {ActualsGroupDefintionsDataTypes} from "@/models/api/Enums/actuals-group-defintions-data-types";
import { v4 as uuidv4 } from 'uuid';
import {DateFormat} from "@/plugins/dates";
import { useDepartmentStore } from "@/lib/stores/DepartmentStore";
import {useShiftHighLevelActuals} from "@/lib/stores/Shift/ShiftHighLevelActualsStore";
import {LocationPlannedHighLevelActualsViewModel} from "@/models/api";
import { EventBus, Events } from '@/lib/EventBus';
import Logging from '@/lib/Logging';
import DownloadHelper from '@/lib/DownloadHelper';
import dayjs from 'dayjs';

export default {
  components: {ShiftLoader},
  computed: {
    ...mapState(useDepartmentStore, ['departmentId', 'locations', 'locationName', 'taskTypes']),
    ...mapState(useShiftDetails, ['shiftId', 'shiftName', 'shiftStartTime', 'supervisorShiftReport']),
    ...mapState(useSystemStore, ['staffMembers']),
    ...mapState(useShiftHighLevelActuals, ['shiftPredictions']),
  },
  data() {
    return {
      heading: 'Actuals Entry',
      editing: true,
      uiLocked: false,
      dataLoaded: false,
      entriesColumnCount: 0,
      formData: {
        actualsRows: []
      },
      actualsGroups: [],
      equipmentList: null,
      locationList: null,
      taskTypeList: null,
      operatorsList: null,
      pageStatus: '',
      saving: false,
      debouncedSaveFn: null,
      formulaDependencies: [],
      totals: {},
      tableKey: 0,  // Add this counter to force the table to re-render when the data changes,
      definitionsIndex: [], // For quick look up of definitions
      shiftIdentifier: '',
      filterOptions: {
        equipments: [],
        locations: [],
        taskTypes: [],
        operators: []
      },
      filterEquipment: null,
      filterLocation: null,
      filterTaskType: null,
      filterOperator: null,
      activeFilteredEquipment: null,
      activeFilteredLocation: null,
      activeFilteredTaskType: null,
      activeFilteredOperator: null,
      filterRefresh: false,
      sortColumn: 'taskType',
      sortDirection: 'up',
      showExportToCsvModal: false,
      csvExportOption: null,
      csvExportDateRangeStart: null,
      csvExportDateRangeEnd: null,
      supervisorReportId: null,
      highLevelActualsId: null,
      metresAdvancedPlanned: null,
      metresAdvancedSupervisor: null,
      metresAdvancedActual: null,
      metresAdvancedChanged: false,
      filterOptionsAll: { key: null, value: 'All' }
    };
  },
  created() {
    this.debouncedSaveFn = _.debounce(() => {
      if (!this.saving) {
        this.save();
      }
    }, 5000);
    
    this.filterEquipment = this.filterOptionsAll;
    this.filterLocation = this.filterOptionsAll;
    this.filterTaskType = this.filterOptionsAll;
    this.filterOperator = this.filterOptionsAll;
  },
  methods: {
    changeSorting(column) {
      if (this.sortColumn === column) {
        this.sortDirection = this.sortDirection === 'up' ? 'down' : 'up';
      }
      else {
        this.sortColumn = column;
        this.sortDirection = 'up';
      }
      
      this.sort(this.formData.actualsRows);
    },
    sort(actualsRows) {      
      actualsRows.sort((a, b) => {
        
        let valueA = '';
        let valueB = '';
        
        if (this.sortColumn === 'equipment') {
          valueA = a.equipment?.name ?? '';
          valueB = b.equipment?.name ?? '';
        }
        else if (this.sortColumn === 'location') {
          valueA = a.location?.name ?? '';
          valueB = b.location?.name ?? '';
        }
        else if (this.sortColumn === 'taskType') {
          valueA = a.taskType?.name ?? '';
          valueB = b.taskType?.name ?? '';
        }
        else if (this.sortColumn === 'operator') {
          valueA = a.operator?.name ?? '';
          valueB = b.operator?.name ?? '';
        }
        
        if (this.sortDirection === 'up')
          return valueA.localeCompare(valueB);
        else 
          return valueB.localeCompare(valueA);
      });
    },
    getShiftName() {
      return `${this.shiftName} - ${dayjs(this.shiftStartTime).format(DateFormat.WeekDisplay)}`;
    },
    async downloadCSV(filename = 'export.csv') {
      if (!this.$refs.form.validate()) {
        return;
      }
            
      if (this.csvExportOption === 'currentDisplay') {
        const csvData = this.convertToCSV()
        let blob = DownloadHelper.makeBlobFromFileString(csvData);
        DownloadHelper.download('ActualsReport.csv', blob);
      }
      else {
        let response = await Actuals.downloadCsv(this.departmentId, this.csvExportDateRangeStart, this.csvExportDateRangeEnd)

        let blob = new Blob([response.data], { type: "text/csv" });
        DownloadHelper.download(`ActualsReport_${this.csvExportDateRangeStart}_${this.csvExportDateRangeEnd}.csv`, blob);
      }

      this.showExportToCsvModal = false
    },
    convertToCSV() {      
      const headers = [
        'Shift',
        'Equipment',
        'Location',
        'Task Type',
        'Operator',
        'Comments',
        'Start Time',
        'End Time',
        'Planned Metres Advanced',
        'Supervisor Metres Advanced',
        'Actual Metres Advanced',
        ...this.actualsGroups.flatMap(group => group.actualGroupDefinitions.map(def => `${group.groupName}_${def.groupDefinitionName}`))
      ]
      
      const csvRows = [
        headers.join(','), // Add the header row
        ...this.formData.actualsRows.map(row => {
          let hla = this.getMetresAdvancedByLocationId(row.locationId);
          
          const rowData = [
            `"${this.shiftIdentifier}"`,
            row.equipment.name,
            row.location.name,
            row.taskType.name,
            row.operator.name,
            `"${ row.comment ? row.comment.replace('"', '\'') : '' }"`,
            row.startTime,
            row.endTime,
            hla.metresAdvancedPlanned,
            hla.metresAdvancedSupervisor,
            hla.metresAdvancedActual,
            ...this.actualsGroups.flatMap(group => group.actualGroupDefinitions.map(def => row.actualsPairs[`${group.groupId}_${def.groupDefinitionId}`] ?? ''))
          ]
          
          return rowData.join(',')
        })
      ]

      return csvRows.join('\n')
    },
    async populateShiftData() {
        this.$wait.start('loading data');

        try {
            await Actuals.populateFromShiftPlan(this.shiftId);

            EventBus.$emit(Events.ToastSuccess, `Actuals populated from shift plan`);

            await this.loadData();
        } catch(e) {
            Logging.error(e);
            EventBus.$emit(Events.ToastError, `Error populating actuals from shift plan: ${e.message}`);
        } finally {
            this.$wait.end('loading data');
        }
    },
    processFormulasInDefinitions() {
      for (let g = 0; g < this.actualsGroups.length; g++) {
        let currentGroup = this.actualsGroups[g];

        for (let d = 0; d < currentGroup.actualGroupDefinitions.length; d++) {
          let item = currentGroup.actualGroupDefinitions[d];
          
          if (!item.formula)
            continue;

          item.formulaDecimalPlaces = item.decimalPlaces;          
          let lowercaseFormula = item.formula.toLowerCase();
          item.processedFormula = lowercaseFormula;
          
          const regex = /\[(.*?)\]/g;
          item.isFormulaProcessed = true;

          for (const match of lowercaseFormula.matchAll(regex)) {
            let definitionName = match[1]

            const foundDefinition = currentGroup.actualGroupDefinitions.find(i => i.groupDefinitionName.toLowerCase() === definitionName.toLowerCase());
            if (!foundDefinition) {
              item.isFormulaProcessed = false;            
              break;
            }

            let definitionId = foundDefinition.groupDefinitionId;
                        
            // Add to formulaDependencies to know which column to trigger recalculation
            if (!this.formulaDependencies.includes(`${currentGroup.groupId}_${definitionId}`))
              this.formulaDependencies.push(`${currentGroup.groupId}_${definitionId}`);

            item.processedFormula = item.processedFormula.replace(`[${definitionName}]`, `[${currentGroup.groupId}_${definitionId}]`)
          }

          if (item.isFormulaProcessed) 
            item.formulaDescription = item.formula;
          else {
            Logging.warning("Error processing formula", item.formula);
            item.formulaDescription = 'Error processing formula: ' + item.formula ;
          }
        }
      }
    },    
    async taskTypeChanged() {
      const uniqueTaskTypeIds = [...new Set(
          this.formData.actualsRows
              .map(row => row.isCustom ? row.taskType?.id : row.taskTypeId )
              .filter(Boolean) // Remove falsy values
      )]
      
      this.actualsGroups = await Actuals.getGroups(uniqueTaskTypeIds);
      
      this.processFormulasInDefinitions();
      
      // Trigger table to re-render
      this.tableKey += 1
    },
    handleMetresAdvancedChanged() {
      this.metresAdvancedChanged = true;
      this.pageStatus = "Not Saved";
      this.debouncedSaveFn();
    },
    handleInputChange(row, actualsChanged, headerGroup, target) {      
      switch (target) {
        case 'taskType':
          row.taskTypeId = row.taskType.id;
          this.taskTypeChanged();
          break;
        case 'operator':
          row.operatorId = row.operator.id;
          break;
        case 'location':
          row.locationId = row.location.id;
          break;
        case 'equipment':
          row.equipmentId = row.equipment.id;
          break;
      }
      
      if (actualsChanged && this.formulaDependencies.includes(`${headerGroup.groupId.toLowerCase()}_${actualsChanged.groupDefinitionId.toLowerCase()}`)) {
        this.recalculateFormulaForRow(row);
      }

      this.recalculateTotals();
      
      row.status = 'Not Saved';
      this.pageStatus = "Not Saved";
      this.debouncedSaveFn();            
    },
    recalculateTotals() {
      // TODO: Optimize recalculation of totals to limit it to only the changed column (taking into account formula recalculations)
      
      for (let g = 0; g < this.actualsGroups.length; g++) {
        let currentGroup = this.actualsGroups[g];

        let lowercasedGroupId = currentGroup.groupId.toLowerCase();

        for (let d = 0; d < currentGroup.actualGroupDefinitions.length; d++) {
          let currentDefinition = currentGroup.actualGroupDefinitions[d];
          
          if (!currentDefinition.summable || (
              currentDefinition.groupDefinitionType !== 'Decimal' && currentDefinition.groupDefinitionType !== 'Integer' && currentDefinition.groupDefinitionType !== 'Formula'
          ))
            continue;

          let cellId = `${lowercasedGroupId}_${currentDefinition.groupDefinitionId}`;
          let currentTotal = 0;
                    
          this.formData.actualsRows.map(r => {
            if (r.actualsPairs[cellId] !== undefined)
              currentTotal += +r.actualsPairs[cellId];
          })
          
          let decimalPlaces = currentDefinition.decimalPlaces ? currentDefinition.decimalPlaces : 1;
          if (currentDefinition.groupDefinitionType === 'Formula' || currentDefinition.groupDefinitionType === 'Decimal') {
            currentTotal = currentTotal.toFixed(decimalPlaces);
          }
          
          this.totals[cellId] = currentTotal;
        }
      }
    },
    recalculateFormulaForRow(row) {      
      // Have to do it from actualsGroups because actualValues only come back when there are values      
      for (let g = 0; g < this.actualsGroups.length; g++) {
        let currentGroup = this.actualsGroups[g];
        
        // Do not continue with group if not associated with the taskType
        if (!currentGroup.taskTypes.some(tt => tt.taskTypeId === row.taskType.id))
          continue;

        let lowercasedGroupId = currentGroup.groupId.toLowerCase();

        for (let d = 0; d < currentGroup.actualGroupDefinitions.length; d++) {
          let currentDefinition = currentGroup.actualGroupDefinitions[d];
          
          if (currentDefinition.groupDefinitionType === 'Formula' && currentDefinition.isFormulaProcessed) {

            const regex = /\[(.*?)\]/g;
            
            let formulaExpression = currentDefinition.processedFormula;

            let hasUnmatched = false;
            for (const match of formulaExpression.matchAll(regex)) {
              let matchedValue = row.actualsPairs[match[1]];
              
              if (matchedValue === undefined)
                hasUnmatched = true;
              
              formulaExpression = formulaExpression.replace(`[${match[1]}]`, `${matchedValue}`)              
            }
            
            // Check that all [x] expressions have been found
            if (formulaExpression.indexOf('[') < 0 && !hasUnmatched) {
              let evaluatedResult = eval(formulaExpression).toFixed(currentDefinition.formulaDecimalPlaces);
              
              // If we want to remove trailing 0s
              // evaluatedResult = parseFloat(evaluatedResult);

              row.actualsPairs[lowercasedGroupId + "_" + currentDefinition.groupDefinitionId.toLowerCase()] = evaluatedResult;
            }
          }
        }
      }
    },
    async save(switchToReadonlyWhenDone = false) {     
      this.uiLocked = true;
      this.saving = true;
      this.pageStatus = "Saving..."
      
      // Process and save all actuals rows that have been modified 
      let updateCommands = [];      
      for (let ri = 0; ri < this.formData.actualsRows.length; ri++)
      {
        let rowToSave = this.formData.actualsRows[ri];
        
        // Do not process row for saving if the taskType is null
        if (!rowToSave.taskType) 
          continue;

        if (rowToSave.status === 'Not Saved') {
          rowToSave.status = 'Saving...';

          let updateCommand = {
            id: rowToSave.id,
            locationId: rowToSave.location ? rowToSave.location.id : null,
            equipmentId: rowToSave.equipment ? rowToSave.equipment.id : null,
            operatorId: rowToSave.operator ? rowToSave.operator.id : null,
            taskTypeId: rowToSave.taskType ? rowToSave.taskType.id : null,
            comment: rowToSave.comment,
            startTime: rowToSave.startTime,
            endTime: rowToSave.endTime,
            isCustom: rowToSave.isCustom,
            actualGroupDefinitions: []
          }

          for (let g = 0; g < this.actualsGroups.length; g++) {
            let currentGroup = this.actualsGroups[g];

            if (currentGroup.taskTypes.some(tt => tt.taskTypeId === rowToSave.taskType.id)) {
              let lowercasedGroupId = currentGroup.groupId.toLowerCase();

              for (let d = 0; d < currentGroup.actualGroupDefinitions.length; d++) {
                let currentDefinition = currentGroup.actualGroupDefinitions[d];

                // Do not add if there are no recorded values
                if (!rowToSave.actualsPairs[`${lowercasedGroupId}_${currentDefinition.groupDefinitionId}`])
                  continue;

                let defValue = {
                  actualsGroupDefinitionId: currentDefinition.groupDefinitionId,
                  actualsGroupId: lowercasedGroupId,
                  value: rowToSave.actualsPairs[`${lowercasedGroupId}_${currentDefinition.groupDefinitionId}`],
                  type: this.getTypeStringAsNumber(currentDefinition.groupDefinitionType)
                }

                updateCommand.actualGroupDefinitions.push(defValue);
              }
            }
          }

          updateCommands.push(updateCommand);
        }
      }
      
      let saveError = false;
      try {
        // Save actual rows
        if (updateCommands.length > 0) {
          await Actuals.update(this.shiftId, updateCommands);
        }

        // Save actual metres advanced if changed
        if (this.metresAdvancedChanged) {
          await Shift.updatePlodHighLevelActuals(this.shiftId, {
            reportId: this.supervisorReportId,
            shiftId: this.shiftId,
            highLevelActuals: [
              {
                id: this.highLevelActualsId,
                locationId: this.activeFilteredLocation.key,
                actualMetresAdvanced: this.metresAdvancedActual
              }
            ]
          })
        }
      } catch(e) {        
        saveError = true;
        Logging.error(e);
      } finally {
        this.saving = false;
        this.uiLocked = false;

        if (!saveError) {
          this.pageStatus = "Saved";
          this.metresAdvancedChanged = false;

          this.formData.actualsRows.map(i => {
            i.status = '';
          });

          if (switchToReadonlyWhenDone && !saveError) {
            this.editing = false;
            this.uiLocked = true;
            this.pageStatus = "";
          }
        }
        else
          this.pageStatus = "Error";
      }
    },
    getTypeStringAsNumber(typeString) {
      switch (typeString) {
        case "Integer":
          return 0;
        case "Decimal":
          return 1;
        case "Time":
          return 2
        case "Text":
          return 3
        case "Formula":
          return 4
        case "Boolean":
          return 5
      }
    },
    validateInput(event, dataType) {
      let newValue = event.target.value + event.key

      let validationRegex = null;

      if (dataType === "Integer")
        validationRegex = /^-?\d+$/;
      else if (dataType === "Decimal")
        validationRegex = /^-?\d*\.?\d*$/;

      if (validationRegex && !validationRegex.test(newValue))
      {
        event.preventDefault();
      }
    },
    switchToEdit() {
      this.editing = true;
      this.uiLocked = false;
      this.pageStatus = '';
    },
    finishEditing() {
      if (this.pageStatus === 'Not Saved') {
        if (this.debouncedSaveFn)
          this.debouncedSaveFn.cancel();
        
        this.save(true);
      }
      else {
        this.editing = false;
        this.uiLocked = true;
      }
    },
    addRow() {
      let newItem = {
        id: uuidv4(),
        actualsPairs: {},
        status: 'Not Saved',
        isCustom: true,
      }
      
      // Set item default values that are uneditable, if filter has been applied

      if (this.filterEquipment) 
        newItem.equipment = this.equipmentList.find(i => i.id === this.filterEquipment?.key?.toLowerCase());

      if (this.filterLocation)
        newItem.location = this.locationList.find(i => i.id === this.filterLocation?.key?.toLowerCase());

      if (this.filterOperator)
        newItem.operator = this.operatorsList.find(i => i.id === this.filterOperator?.key?.toLowerCase());

      if (this.filterTaskType)
        newItem.taskType = this.taskTypeList.find(i => i.id === this.filterTaskType?.key?.toLowerCase());
      
      this.formData.actualsRows.push(newItem)
    },
    clearFilter() {
      this.filterEquipment = this.filterOptionsAll;
      this.filterLocation = this.filterOptionsAll;
      this.filterTaskType = this.filterOptionsAll;
      this.filterOperator = this.filterOptionsAll;
      
      this.activeFilteredEquipment = null;
      this.activeFilteredLocation = null;
      this.activeFilteredTaskType = null;
      this.activeFilteredOperator = null;
      
      this.loadData(true);
    },
    async loadData(filterRefresh = false) {
      if (!this.csvExportDateRangeStart && this.shiftStartTime)
        this.csvExportDateRangeStart = this.shiftStartTime.format('YYYY-MM-DD')
      
      if (!this.csvExportDateRangeEnd && this.shiftStartTime)
        this.csvExportDateRangeEnd = this.shiftStartTime.format('YYYY-MM-DD')
      
      this.filterRefresh = filterRefresh;
                  
      this.shiftIdentifier = this.getShiftName();
      
      this.equipmentList = await Equipment.get(['EquipmentRole', 'Departments']);
      this.locationList = this.locations;
      this.taskTypeList = this.taskTypes;
      this.operatorsList = this.staffMembers;
      
      const options = await Actuals.getFilterOptionsForDepartmentShiftId(this.shiftId);
      
      this.filterOptions.locations = [this.filterOptionsAll, ...this.locations.map(l => {return { key: l.id, value: l.name }})];
      this.filterOptions.equipments = [this.filterOptionsAll, ...options.equipments];
      this.filterOptions.taskTypes = [this.filterOptionsAll, ...options.taskTypes];
      this.filterOptions.operators = [this.filterOptionsAll, ...options.operators];
            
      let actualsRows = await Actuals.getActualsDataForDepartmentShiftId(
          this.shiftId,
          this.filterTaskType?.key,
          this.filterOperator?.key,
          this.filterEquipment?.key,
          this.filterLocation?.key
      );
            
      // Get unique taskTypeIds from actuals data
      const uniqueTaskTypeIds = Array.from(
          new Set(actualsRows.flatMap(a => a.taskTypeId))
      );
      
      // Get groups
      if (uniqueTaskTypeIds && uniqueTaskTypeIds.length > 0) {
        this.actualsGroups = await Actuals.getGroups(uniqueTaskTypeIds)
      }

      this.processFormulasInDefinitions();

      
      // Load all definitions into an indexable object for reuse
      /*
      this.definitionsIndex = this.actualsGroups.reduce((acc, group) => {
        group.actualGroupDefinitions.map(def => {
          acc[`${def.groupDefinitionId}`] = def;
        })

        return acc;
      }, {});      
      */
      
      // Once mounted, massage data 
      actualsRows.map(row => {
        // 1. Change time format to HH:mm
        row.actualValues.map(item => {
          if (item.type === 'Time' && item.value) {
            // Convert to HH:mm
            let date = new Date(item.value);
            item.value = date.toISOString().substr(11, 5);
          }
          
          if (item.value && (item.type === 'Decimal' || item.type === 'Formula') && item.decimalPlaces) 
            item.value = item.value.toFixed(item.decimalPlaces);
        });

        // 2. Reduce actuals data to a key value pair, with ID being a concatenation of groupId and defId
        row.actualsPairs = row.actualValues.reduce((acc, item) => {
          acc[`${item.groupId.toLowerCase()}_${item.actualValueId.toLowerCase()}`] = item.value;
          
          return acc;
        }, {});
        
        // 3. Massage equipment, location, taskType and operator into objects needed for combo boxes
        row.equipment = {
          id: row.equipmentId,
          name: row.equipmentName
        }
        
        row.taskType = {
          id: row.taskTypeId,
          name: row.taskTypeName,
        }
        
        row.location = {
          id: row.locationId,
          name: row.locationName
        }
        
        row.operator = {
          id: row.operatorId,
          name: row.operatorName
        }
      });
      
      this.sort(actualsRows);
      
      this.formData.actualsRows = actualsRows;
      
      // Calculate formula for each row
      this.formData.actualsRows.map(row => {
        this.recalculateFormulaForRow(row);
      });

      // Calculate totals
      this.recalculateTotals();
      
      if (this.filterEquipment.key)
        this.activeFilteredEquipment = this.filterEquipment;
      if (this.filterLocation.key)
        this.activeFilteredLocation = this.filterLocation;
      if (this.filterTaskType.key)
        this.activeFilteredTaskType = this.filterTaskType;
      if (this.filterOperator.key)
        this.activeFilteredOperator = this.filterOperator;
            
      this.dataLoaded = true;
      this.filterRefresh = false;
      
      if (this.filterLocation && this.filterLocation.key) {
        const metresAdvanced = this.getMetresAdvancedByLocationId(this.filterLocation.key);
        
        this.supervisorReportId = this.supervisorShiftReport?.id;
        this.highLevelActualsId = metresAdvanced.highLevelActualsId;
        this.metresAdvancedPlanned = metresAdvanced.metresAdvancedPlanned;
        this.metresAdvancedSupervisor = metresAdvanced.metresAdvancedSupervisor;
        this.metresAdvancedActual = metresAdvanced.metresAdvancedActual;
      }
    },
    getMetresAdvancedByLocationId(locationId) {
      const selectedHighLevelActuals = this.supervisorShiftReport?.highLevelActuals?.find(i => i.locationId?.toLowerCase() === locationId?.toLowerCase());
      const selectedLocationShiftPrediction = this.shiftPredictions?.find(i => i.locationId?.toLowerCase() === locationId?.toLowerCase());

      if (selectedHighLevelActuals)
        return {
          highLevelActualsId: selectedHighLevelActuals.id,
          metresAdvancedPlanned: selectedHighLevelActuals.plannedMetresAdvanced,
          metresAdvancedSupervisor: selectedHighLevelActuals.supervisorMetresAdvanced,
          metresAdvancedActual: selectedHighLevelActuals.actualMetresAdvanced
        };
      else
        return {
          metresAdvancedPlanned: selectedLocationShiftPrediction?.plannedMetresAdvanced
        };
    },
    getTextFieldType(type) {
      if (type === 'Time')
        return 'time';
      else
        return 'text';
    }
  },
};
</script>

<style scoped>
.totals-row > td {
  border-top: 3px SOLID #EEE !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr.edit-mode-colors > th {
  color: #FFF !important;
}

.col-boolean {
  min-width: 50px;
}

.col-decimal {
  min-width: 70px;
}

.col-integer {
  min-width: 70px;
}

.col-formula {
  min-width: 70px;
}

.col-text {
  min-width: 100px;
}

.col-time {
  min-width: 120px;
}

/*
.edit-mode-colors .formula-indicator {
  color: darkorange;
}
 */

.formula-indicator {
  color: darkorange;
  font-style: italic;
}

.edit-mode-colors {
  background-color: #15A69A;
}

.read-mode-colors {
  background-color: #EEEEEE;
}

.header-s {
  font-size: 12px;
}

.custom-table th,
.custom-table td {
  border: 1px solid #CCC;
  padding: 8px 5px !important; 
  height: auto !important;
  text-align: center !important;
}

.custom-table {
  & table {
    /* 
    Note: collapse doesn't quite work at this point because the table border gets rendered behind the cells and shifts.
    border-collapse: collapse; 
    */
  }
}

.custom-table {
  & td {
    border: 0 solid #ccc;
    padding: 10px 5px 10px 5px !important;
    text-align: center;
  }
}

.extra-wide {
  min-width: 150px;
}

.actions-column {
  width: 50px;
}

.indicator-column {
  min-width: 50px;
}

.group-name {
  font-style: italic;
}

.small-input {
  font-size: 14px;
  margin-top: 0;
}

.add-new-row-button {
  margin-left: 10px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table_wrapper {
  overflow: auto; 
  max-width: 100%; 
  position: relative;
}

.v-input--is-disabled {
  opacity: 0.4;
}

.status-chips {
  font-weight: bold;
}

.sortable-field {
  cursor: pointer;
}

.filter-button {
  width: 90px;
}

.clear-filter {
  min-width: 40px !important;
  width: 40px;
}

.filter-options {
  width: 170px;
}

input[readonly] {
  font-style: italic;
  color: #777;
}

/* Make the first four columns sticky */
th.glued-edit:nth-child(-n+5) {
  position: sticky;
  background-color: #15A69A; /* Ensure the background isn't transparent */
  z-index: 1; /* Ensure sticky columns appear above other cells */
}

th.glued-read:nth-child(-n+5) {
  position: sticky;
  background-color: #EEE; /* Ensure the background isn't transparent */
  z-index: 1; /* Ensure sticky columns appear above other cells */
}

td:nth-child(-n+5) {
  position: sticky;
  background-color: #FFF; /* Ensure the background isn't transparent */
  z-index: 1; /* Ensure sticky columns appear above other cells */
}

/* Set the left position for each sticky column */
th:nth-child(1),
td:nth-child(1) {
  left: 0
}

th:nth-child(2),
td:nth-child(2) {
  left: 50px; /* Adjust based on the width of the first column */
}

th:nth-child(3),
td:nth-child(3) {
  left: 200px; /* Adjust based on the combined width of the first two columns */
}

th:nth-child(4),
td:nth-child(4) {
  left: 350px; /* Adjust based on the combined width of the first two columns */
}

th:nth-child(5),
td:nth-child(5) {
  left: 500px; /* Adjust based on the combined width of the first two columns */
}

tbody tr:hover {
  background-color: transparent !important;
}



.some-style >>> .v-input__slot::before {
  border-style: none !important;
}



</style>