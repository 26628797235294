<template>
    <div class="pa-5">
        <v-card v-if="data">
            <v-card-title>
                <h1 class="title">{{ 'Tasks' | term }}</h1>
                <v-spacer></v-spacer>
                <v-select
                    v-if="departments"
                    :items="departments"
                    item-value="id"
                    item-text="name"
                    v-model="selectedDepartment"
                    label="Department"
                    single-line
                    hide-details
                    class="admin-table-filter"
                    :menu-props="{ dark: true }"
                >
                    <template v-slot:item="data">
                        <template v-if="data.item.isGroup">
                            <v-list-item-content
                                class="caption"
                                @click="$event.stopPropagation()"
                                v-text="data.item.name"
                            ></v-list-item-content>
                        </template>
                        <template v-else>
                            <v-list-item-content class="pl-5" v-text="data.item.name"></v-list-item-content>
                        </template>
                    </template>
                </v-select>
            </v-card-title>
            <empty-state v-if="!selectedDepartment" icon="mdi-alert-circle-outline" message="Select a department"></empty-state>

            <v-card-title v-if="!!selectedDepartment">
                <h2 class="subtitle-1">System {{ 'Tasks' | term }}</h2>
            </v-card-title>
            <v-data-table
                disable-pagination
                hide-default-footer
                :headers="headers"
                :items="filteredSystem"
                v-if="!!selectedDepartment"
            >
                <template v-slot:item.defaultTimeMinutes="{ item }">
                    <span v-if="item.rateMetric != null" class="font-italic">By {{ rateMetricNames[item.rateMetric] }}</span>
                    <span v-else>{{ item.defaultTimeMinutes }}</span>
                </template>
                <template v-slot:item.x="{ item }">
                    <div class="my-1" style="max-width: 500px;">
                        <week-time-period-display
                            :name="item.name"
                            :colour="item.backgroundColour"
                            :text-colour="item.foregroundColour"
                            :is-delay="item.isDelay"
                            :hide-name-if-delay="false"
                        >
                        </week-time-period-display>
                    </div>
                </template>
                <template v-slot:item.id="{ item }">
                    <v-btn text icon @click="edit(item)" class="float-right">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
            </v-data-table>

            <v-card-title v-if="!!selectedDepartment">
                <h2 class="subtitle-1">Custom {{ 'Tasks' | term }}</h2>
                <v-spacer></v-spacer>
                <v-btn small color="secondary" class="ml-3" @click="create()" v-if="!!selectedDepartment">
                    <v-icon left>mdi-plus</v-icon>Add
                </v-btn>
                <v-menu
                    v-model="importDialog"
                    max-width="310px"
                    :close-on-content-click="false"
                    v-if="!!selectedDepartment"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" small class="ml-1" color="secondary">
                            <v-icon left>mdi-upload</v-icon>Import
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-text>
                            <input ref="inputUpload" type="file" accept="text/csv" @change="getFile($event)" />
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="secondary" @click="importData">Upload</v-btn>
                            <v-btn text @click="importDialog = false">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
                <v-btn
                    small
                    class="ml-1"
                    color="gray"
                    v-if="!!selectedDepartment && filteredUserDefined.length"
                    @click="exportData()"
                >
                    <v-icon left>mdi-download</v-icon>Export
                </v-btn>
            </v-card-title>
            <v-data-table
                disable-pagination
                hide-default-footer
                :headers="headers"
                :items="filteredUserDefined"
                v-if="!!selectedDepartment"
            >
                <template v-slot:item.defaultTimeMinutes="{ item }">
                    <span v-if="item.rateMetric != null" class="font-italic">By {{ rateMetricNames[item.rateMetric] }}</span>
                    <span v-else>{{ item.defaultTimeMinutes }}</span>
                </template>
                <template v-slot:item.x="{ item }">
                    <div class="my-1" style="max-width: 500px;">
                        <week-time-period-display
                            :name="item.name"
                            :colour="item.backgroundColour"
                            :text-colour="item.foregroundColour"
                            :is-delay="item.isDelay"
                            :hide-name-if-delay="false"
                        >
                        </week-time-period-display>
                    </div>
                </template>
                <template v-slot:item.id="{ item }">
                    <v-btn text icon @click="edit(item)" class="float-right">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </v-card>
        <standard-dialog :render="!!selected" :value='showModal' :max-width="900" persistent>
            <template v-slot:title>
                <span v-if="selected.id">Edit</span>
                <span v-else>Create</span>
                <span class="ml-1">{{ 'Task' | term }}</span>
            </template>
            <template v-slot:content>
                <v-form ref="form" :lazy-validation="true">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" class="pa-2">
                                <v-alert type="info" class="white--text" style="font-size:0.9em">
                                    <strong>Naming Tasks</strong> - 'future tense' and 'in progress' are the names
                                    used to refer to this task in the future or in progress. E.g. for 'Bog' the
                                    future tense would be 'To Bog' and in progress would be 'Bogging'
                                </v-alert>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="9" class="pa-2">
                                <v-text-field
                                    autocomplete="off"
                                    label="Name"
                                    :rules="[$rules.required(), $rules.maxLength(50)]"
                                    v-model="selected.name"
                                    hint="e.g. Bolt & Mesh"
                                    counter="50"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3" class="pa-2">
                                <v-text-field
                                    autocomplete="off"
                                    label="Abbreviation"
                                    :rules='[$rules.notWhitespace(), $rules.maxLength(20)]'
                                    v-model="selected.abbrName"
                                    hint="e.g. B & M"
                                    counter="20"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" class="pa-2">
                                <v-text-field
                                    autocomplete="off"
                                    label="Name (Future Tense)"
                                    :disabled="selected.isDelay"
                                    :rules="[$rules.requiredIf(() => !selected.isDelay), $rules.maxLength(50)]"
                                    v-model="selected.nameToDo"
                                    hint="e.g. To Bolt & Mesh"
                                    counter="50"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" class="pa-2">
                                <v-text-field
                                    autocomplete="off"
                                    label="Name when in Progress"
                                    :disabled="selected.isDelay"
                                    :rules="[$rules.requiredIf(() => !selected.isDelay), $rules.maxLength(50)]"
                                    v-model="selected.nameInProgress"
                                    hint="e.g. Bolting & Meshing"
                                    counter="50"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="pa-2">
                                <v-select
                                    autocomplete="off"
                                    label="Category Type"
                                    v-model="selected.taskCategoryId"
                                    type="number"
                                    item-value="id"
                                    item-text="name"
                                    :items="filteredTaskCategories"
                                    :menu-props="{ dark: true }"
                                    clearable
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row v-if="isAdmin">
                            <v-col cols="12" class="pa-2">
                                <v-alert type="warning" class="white--text" style="font-size:0.9em">
                                    <strong>System Task Class</strong> - This a system setting and changing this will have system wide effects. Changing task class can change system behaviour.
                                    This is an administration action only.
                                </v-alert>
                            </v-col>
                        </v-row>
                        <v-row v-if="isAdmin">
                            <v-col cols="12" class="pa-2">
                                <v-select
                                    autocomplete="off"
                                    label="System Task Class"
                                    v-model="selected.taskClass"
                                    item-value="value"
                                    item-text="name"
                                    :items="taskClasses"
                                    :menu-props="{ dark: true }"
                                    clearable
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-radio-group
                                    v-model="taskKind"
                                    row
                                    :disabled="isSystemTask(selected)"
                                    @change="onChangeTaskKind()"
                                >
                                    <v-radio
                                        :value="TaskKind.Duration"
                                        label="Duration"
                                    ></v-radio>
                                    <v-radio
                                        :value="TaskKind.Delay"
                                        label="Delay"
                                    ></v-radio>
                                    <v-radio
                                        :value="TaskKind.Rate"
                                        label="Rate"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" class="pa-2" v-if="taskKind === TaskKind.Duration || taskKind == TaskKind.Delay">
                                <v-text-field
                                    autocomplete="off"
                                    label="Default time (mins)"
                                    :rules="[$rules.required(), $rules.multipleOf(15, '15 minute increments only')]"
                                    v-model="selected.defaultTimeMinutes"
                                    type="number"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4" class="pa-2" v-if="taskKind === TaskKind.Delay">
                                <v-select
                                    autocomplete="off"
                                    label="Delay Type"
                                    v-model="selected.delayType"
                                    :rules="[$rules.required()]"
                                    type="number"
                                    :items="['Float', 'Fixed']"
                                    :menu-props="{ dark: true }"
                                ></v-select>
                            </v-col>
                            <v-col cols="4" class="pa-2" v-if="taskKind === TaskKind.Rate">
                                <v-select
                                    autocomplete="off"
                                    label="Metric"
                                    v-model="selected.rateMetric"
                                    :items="rateMetrics"
                                    :rules="[$rules.notNull()]"
                                    :menu-props="{ dark: true }"
                                    :disabled="isSystemTask(selected)"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="5">
                                <h2 class="pa-2 title">Task Colour</h2>
                                <v-color-picker
                                    class="ma-2"
                                    v-model="selected.backgroundColour"
                                    :swatches="swatch"
                                    show-swatches
                                ></v-color-picker>
                            </v-col>
                            <v-col cols="5" v-if="!selected.isDelay">
                                <h2 class="pa-2 title">Text Colour</h2>
                                <v-color-picker
                                    class="ma-2"
                                    v-model="selected.foregroundColour"
                                    :swatches="textSwatches"
                                    show-swatches
                                ></v-color-picker>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="2">Sample:</v-col>
                            <v-col cols="6">
                                <div style="max-width: 500px;">
                                    <week-time-period-display
                                        :name="selected.name"
                                        :colour="selected.backgroundColour"
                                        :text-colour="selected.foregroundColour"
                                        :is-delay="selected.isDelay"
                                        :hide-name-if-delay="false"
                                    >
                                    </week-time-period-display>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="pa-2">
                                <v-select
                                    autocomplete="off"
                                    label="Default Split Delay Type"
                                    v-model="selected.defaultSplitDelayTaskTypeId"
                                    :items="floatDelays"
                                    item-value="id"
                                    item-text="name"
                                    hide-details
                                    clearable
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </template>
            <template v-slot:actions>
                <v-btn color='red' text v-if='selected && selected.id' @click='del()'>Delete</v-btn>
                <v-spacer></v-spacer>
                <v-btn color='gray' text @click='showModal = false'>Cancel</v-btn>
                <v-btn color='primary' text @click='save()'>Save</v-btn>
            </template>
            <template v-slot:offline-actions>
                <v-spacer></v-spacer>
                <v-btn color='gray' text @click='showModal = false'>Cancel</v-btn>
                <offline-btn text></offline-btn>
            </template>
        </standard-dialog>
        <confirm ref="confirm"></confirm>
    </div>
</template>
<script>
import { EventBus, Events } from '@/lib/EventBus';
import MineAreas from '@/lib/data/MineAreas';
import TaskTypes from '@/lib/data/TaskTypes';
import Palettes from '@/lib/Palettes';
import TaskCategories from '@/lib/data/TaskCategories';
import AppHttp from '../../lib/AppHttp';
import DownloadHelper from '@/lib/DownloadHelper';
import { RateMetric, TaskClasses } from '@/models/api';
import { TaskKind } from '@/models/client/task-kind';
import BoardTask from '@/components/WeekBoard/Task.vue';
import Security, { Roles } from '@/lib/Security';
import { IsFloatDelayType } from '@/lib/services/TaskType';
import WeekTimePeriodDisplay from '@/components/Board/TimePeriodDisplay.vue';
import ShiftTaskContents from '@/components/ShiftBoard/ShiftTaskContents.vue';

export default {
    components: { WeekTimePeriodDisplay },
    data() {
        return {
            data: null,
            departments: null,
            taskCategories: null,
            selectedDepartment: null,
            showModal: false,
            selected: null,
            importDialog: false,
            file: null,
            textSwatches: [['#000000'], ['#444444'], ['#999999'], ['#ffffff'], ['#ff3333']],
            headers: [
                { text: 'Name', value: 'name', width: '35%' },
                { text: 'Default Time (mins)', value: 'defaultTimeMinutes', width: '15%' },
                { text: 'Sample', value: 'x', width: '35%' },
                { text: '', value: 'id', width: '15%' }
            ],
            TaskKind,
            taskKind: TaskKind.Duration,
            rateMetrics: [
                { value: RateMetric.Distance, text: 'Distance' },
                { value: RateMetric.WeightTonnes, text: 'Weight (t)' },
                { value: RateMetric.WeightKilograms, text: 'Weight (kg)'},
                { value: RateMetric.Count, text: 'Count' },
            ],
            rateMetricNames,
        };
    },
    async created() {
        await this.loadData();
    },
    destroyed() {},
    methods: {
        async loadData() {
            var mineAreas = await MineAreas.get(['Departments', 'Departments.Palettes']);
            this.data = await TaskTypes.get();
            this.taskCategories = await TaskCategories.get([]);

            var departments = [];
            for (let mine of mineAreas) {
                departments.push({ id: mine.id, name: mine.name, isGroup: true });
                for (let dept of mine.departments) {
                    departments.push({
                        id: dept.id,
                        mineAreaId: mine.id,
                        name: dept.name,
                        isGroup: false,
                        palette: Palettes.makePalette(dept.palettes)
                    });
                }
            }
            this.departments = departments;
        },
        create() {
            this.edit({
                departmentId: this.selectedDepartment,
                backgroundColour: '#89c34a',
                foregroundColour: '#333333',
                taskClass: TaskClasses.UserDefined,
            });
        },
        edit(item) {
            this.selected = { ...item };
            this.taskKind = classifyTaskKind(item);
            this.showModal = true;
            setTimeout(() => this.$refs.form.resetValidation(), 1);
        },
        async save() {
            if (!this.$refs.form.validate()) {
                return;
            }

            if(this.selected.id){
                await this.updateTaskType(this.selected);
            } else {
                await this.createTaskType(this.selected);
            }

            EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Task')} Saved`);
            this.showModal = false;
            await this.loadData();
        },
        async del() {
            if (await this.$refs.confirm.openAsDeleteResource(this.$termSync('Task'),  { })) {
                await TaskTypes.delete(this.selected.id);
                EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Task')} Deleted`);
                this.showModal = false;
                await this.loadData();
            }
        },
        async exportData() {
            const result = await TaskTypes.export(this.selectedDepartment);
            var csv = result.csvFile;
            var blob = DownloadHelper.makeBlobFromFileString(csv);
            var selectedDept = this.departments.filter(x => x.id == this.selectedDepartment)[0];
            var deptName = selectedDept.name.replace(/[/\\?%*:|"<>]/g, '');
            DownloadHelper.download(`Tasks-${deptName}.csv`, blob);
        },
        async importData() {
            try {
                this.$wait.start('saving');
                this.uploadResult = null;
                await TaskTypes.import(this.selectedDepartment, this.file);
                this.importDialog = false;
                EventBus.$emit(Events.ToastSuccess, 'Tasks Imported');
                await this.loadData();
            } finally {
                this.$wait.end('saving');
            }
        },

        async getFile(e) {
            this.file = e.target.files[0];
        },

        isSystemTask(taskType) {
            return taskType && taskType.taskClass !== TaskClasses.UserDefined;
        },

        canDelete(taskType) {
            return taskType && taskType.id && !this.isSystemTask(taskType);
        },

        onChangeTaskKind() {
            this.selected.isDelay = this.taskKind == TaskKind.Delay;
            this.selected.delayType = null;
            this.selected.rateMetric = null;
        },
        async updateTaskType(taskType){
            return await TaskTypes.update({
                _type: 'UpdateTaskTypeCommand',
                id: taskType.id,
                name: taskType.name,
                nameToDo: taskType.nameToDo,
                nameInProgress: taskType.nameInProgress,
                abbrName: taskType.abbrName,
                backgroundColour: taskType.backgroundColour,
                foregroundColour: taskType.foregroundColour,
                defaultTimeMinutes: taskType.defaultTimeMinutes,
                isDelay: taskType.isDelay,
                defaultSplitDelayTaskTypeId: taskType.defaultSplitDelayTaskTypeId,
                taskCategoryId: taskType.taskCategoryId,
                delayType: taskType.delayType,
                taskClass: taskType.taskClass,
                rateMetric: taskType.rateMetric
            });
        },
        async createTaskType(taskType){
            return await TaskTypes.create({
                _type: 'CreateTaskTypeCommand',
                departmentId: taskType.departmentId,
                name: taskType.name,
                nameToDo: taskType.nameToDo,
                nameInProgress: taskType.nameInProgress,
                abbrName: taskType.abbrName,
                backgroundColour: taskType.backgroundColour,
                foregroundColour: taskType.foregroundColour,
                defaultTimeMinutes: taskType.defaultTimeMinutes,
                isDelay: taskType.isDelay,
                defaultSplitDelayTaskTypeId: taskType.defaultSplitDelayTaskTypeId,
                taskCategoryId: taskType.taskCategoryId,
                delayType: taskType.delayType,
                taskClass: taskType.taskClass,
                rateMetric: taskType.rateMetric
            });
        }
    },
    computed: {
        filteredSystem() {
            if (!this.data || !this.selectedDepartment) {
                return [];
            }
            return this.data.filter(x => x.departmentId == this.selectedDepartment && x.taskClass !== TaskClasses.UserDefined);
        },
        filteredUserDefined() {
            if (!this.data || !this.selectedDepartment) {
                return [];
            }
            return this.data.filter(x => x.departmentId == this.selectedDepartment && x.taskClass === TaskClasses.UserDefined);
        },
        filteredTaskCategories() {
            if (!this.taskCategories || !this.selectedDepartment) {
                return [];
            }

            var dept = this.departments.filter(x => x.id == this.selectedDepartment)[0];
            return this.taskCategories.filter(x => x.mineAreaId == dept.mineAreaId);
        },
        taskClasses() {
            const classes = Object.entries(TaskClasses)
                .filter(([key, value]) => !isNaN(value))
                .map(([key, value]) => ({name: key, value}));
            return classes;
        },
        delays() {
            if (!this.data || !this.selectedDepartment) {
                return [];
            }
            return this.data.filter(x => x.departmentId == this.selectedDepartment && x.isDelay);
        },
        floatDelays() {
            return this.delays.filter(x=>IsFloatDelayType(x));
        },
        swatch() {
            var dept = this.departments.filter(x => x.id == this.selectedDepartment)[0];
            return dept ? dept.palette : [];
        },
        isAdmin() {
            return Security.hasRole(Roles.Administrator)
        }
    }
};

/**
 * "Task Kind" is a UI concept to distinguish Duration, Delay and Rate tasks.
 * This function infers the task kind from the isDelay and rateMetric task
 * attributes.
 */
function classifyTaskKind(task) {
    if (task.isDelay) {
        return TaskKind.Delay;
    } else if (task.rateMetric != null) {
        return TaskKind.Rate;
    } else {
        return TaskKind.Duration;
    }
}

const rateMetricNames = {
    [RateMetric.Distance]: 'distance',
    [RateMetric.WeightTonnes]: 'weight (t)',
    [RateMetric.WeightKilograms]: 'weight (kg)',
    [RateMetric.Count]: 'count',
}
</script>
